@import "../utils/vars";

.ng-select {
  &.ng-select-focused {
    &:not(.ng-select-opened) {
      > .ng-select-container {
        border-color: #ccc;
        box-shadow: none;
      }
    }
  }

  &.ng-select-opened {
    > .ng-select-container {
      .ng-arrow {
        border-width: 0 4px 4px;
      }
    }
  }

  .ng-arrow-wrapper {
    .ng-arrow {
      border-width: 4px 4px 2px;
    }
  }

  &.ng-select-chips-new {
    .ng-dropdown-panel {
      .ng-dropdown-header {
        border-bottom: 1px solid #DFE3ED;
      }

      .ng-dropdown-panel-items {
        padding: 6px 0;
      }
    }
  }
}

.select-in-datatable {
  .ng-dropdown-panel-items {
    .ng-option {
      font-size: 12px !important;
      padding: 4px 10px !important;
      margin: 4px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background-color: transparent;
        color: $violet;
      }

      &.ng-option-marked {
        background-color: transparent;
      }
    }
  }
}

.ng-dropdown-panel {
  display: block !important;
  position: fixed !important;

  &.select-in-datatable {
    background-color: $white-color !important;
    box-shadow: 0 2px 12px rgba(73, 74, 88, 0.07) !important;
    border-radius: 3px !important;
    border: none !important;
    padding: 12px 0 !important;
  }
}

app-admin-dashboard,
app-patients-red-flags,
app-red-flags,
app-patients {
  .ng-select-container {
    border: none !important;
    background: transparent;
  }

  .ng-clear-wrapper {
    display: none;
  }

  .ng-select {
    .ng-select-container {
      background: transparent;

      &:hover {
        box-shadow: none;
      }

      .ng-value-container {
        padding-left: 0;

        .ng-input {
          > input[readonly] {
            outline: none !important;
            box-shadow: none;

            &:active {
              outline: none !important;
              box-shadow: none;
            }
          }
        }
      }
    }

    &.ng-select-opened {
      > .ng-select-container {
        background-color: transparent;
      }
    }

    &.ng-select-single {
      .ng-select-container {
        display: inline-flex;
        align-items: center;

        .ng-value-container {
          .ng-input {
            top: auto;
          }
        }
      }
    }

    &-container {
      border: none !important;
      background: transparent;
    }
  }

  .ng-clear-wrapper {
    display: none;
  }
}
