@font-face {
  font-family: 'OpenSans-Regular';
  src: url('/assets/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/OpenSans-Regular.woff') format('woff'), url('/assets/fonts/OpenSans-Regular.ttf') format('truetype'), url('/assets/fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url('/assets/fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/OpenSans-SemiBold.woff') format('woff'), url('/assets/fonts/OpenSans-SemiBold.ttf') format('truetype'), url('/assets/fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('/assets/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/OpenSans-Bold.woff') format('woff'), url('/assets/fonts/OpenSans-Bold.ttf') format('truetype'), url('/assets/fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pacifico-regular';
  src: url('/assets/fonts/pacifico-regular-400.woff2') format('woff2'),
  url('/assets/fonts/pacifico-regular-400.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
