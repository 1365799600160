@import "../utils/vars";

app-permission-detail,
app-permissions {
  .breadcrumbs {
    padding-bottom: 12px;
  }

  .sticky-block {
    margin-bottom: 20px;
  }

  .top-bar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 1200px) {
      flex-wrap: nowrap;
    }
    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
    }

    .cell {
      &:first-child {
        padding-top: 0;
        align-items: center;
        @media screen and (max-width: 1200px) {
          order: 1;
          width: auto;
          padding-bottom: 0;
        }
        @media screen and (max-width: 600px) {
          padding-bottom: 10px;
          width: 100%;
        }

        label {
          @media screen and (max-width: 600px) {
            width: 100%;
          }

          input {
            @media screen and (max-width: 600px) {
              width: 100%;
            }
          }
        }
      }

      &:last-child {
        padding-top: 0;
        @media screen and (max-width: 1200px) {
          order: 2;
          padding-bottom: 0;
        }

        .btn {
          @media screen and (max-width: 1200px) {
            margin-right: 0;
          }
        }
      }
    }

    .clear-all {
      padding-top: 0;
      background-position: 0 2px;
    }
  }

  .btn-full-width {
    @media screen and (max-width: 600px) {
      width: 100% !important;
    }
  }

  .text-field {
    display: block;
    padding-bottom: 40px;

    span {
      display: block;
      width: 100%;
      padding-bottom: 5px;
      font-family: $openSansSemiBold;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.5;
      color: #9D9EA8;
    }

    input {
      font-family: $openSansSemiBold;
      color: #4C5560;
      width: 100%;
    }
  }

  .form-title {
    font-family: $openSansRegular;
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0.05em;
    color: #4c5560;
    text-transform: uppercase;
    padding-bottom: 15px;
    border-bottom: 1px solid #e8e9f4;
    margin-bottom: 24px;
  }

  .permission-detail {
    flex-shrink: 0;
    background-color: $white-color;
    box-shadow: 0 2px 12px 0 rgba(46, 55, 65, 0.07);
    padding: 40px 30px 16px 30px;
    margin-top: 20px;
    height: 100%;
    position: relative;
    @media screen and (max-width: 960px) {
      padding: 25px 12px 16px 12px;
    }

    &__container {
      width: 100%;
      max-width: 697px;
      margin: 0 auto;
    }
  }

  .permission-module {
    strong {
      display: block;
      font-family: $openSansBold;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      color: #4C5560;
      padding-bottom: 16px;
    }

    &__list {
      display: flex;
      align-items: flex-start;
      width: 100%;
      margin: 0 0 24px 0;
      background-color: #F8F8FB;
      padding: 8px 0 18px 0;
      position: relative;
      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 22px;
        bottom: 22px;
        width: 1px;
        background-color: #E8E9F4;
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }

    &__cell {
      width: 50%;
      padding: 0 26px;
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0 15px;
      }
    }

    &__item {
      border-bottom: 1px solid #E8E9F4;
      padding: 16px 0;

      &:last-child {
        border-bottom-color: transparent;
      }

      label {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 36px 0 0;
        font-family: $openSansRegular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 133%;
        color: #4C5560;

        &::before {
          position: absolute;
          right: 0;
          top: 1px;
          content: '';
          width: 16px;
          height: 16px;
          border: 1px solid #ABACBB;
          border-radius: 3px;
        }

        &::after {
          content: '';
          display: none;
          position: absolute;
          top: 5px;
          right: 6px;
          width: 4px;
          height: 7px;
          border: solid $violet;
          border-width: 0 2px 2px 0;
          transition: initial;
          transform: rotate(41deg);
        }
      }

      input {
        display: none;

        &:checked {
          + label {
            &::before {
              background-color: #e7e8f8;
            }

            &::after {
              display: block;
            }
          }
        }
      }
    }
  }
}

app-permission-detail {
  .top-bar {
    .cell {
      &:first-child {
        @media screen and (max-width: 600px) {
          order: 1;
        }
      }

      &:last-child {
        @media screen and (max-width: 600px) {
          flex-wrap: wrap;
          order: 2;
        }
      }
    }

    .btn {
      min-width: 125px;
      @media screen and (max-width: 600px) {
        width: 100%;
      }

      &:not(:last-child) {
        @media screen and (max-width: 600px) {
          margin-bottom: 11px;
        }
      }
    }

    .dashboard-title {
      padding-bottom: 0;
    }
  }
}
