@import "../utils/vars";
@import "../utils/mixins";

app-documents,
app-medical-documents-wrapper,
app-admission-document {
  .documents {
    &__content {
      padding: 25px 10px;
      background-color: $white-color;
      box-shadow: 0 2px 12px 0 rgba(46, 55, 65, 0.07);
      overflow: hidden;
    }
  }

  .document-blank {
    font-family: $openSansRegular;
    width: $pageWidth;
    max-width: $pageWidth;
    padding: 10px;
    @include scrollbar($scrollBarColor, transparent);
    @media screen and (max-width: 1360px) {
      transform: scale(0.8);
      transform-origin: 50% 0 0;
    }
    @media screen and (max-width: 1100px) {
      transform: scale(0.7);
      transform-origin: 50% 0 0;
    }
    @media screen and (max-width: 980px) {
      transform: scale(0.6);
      transform-origin: 50% 0 0;
    }
    @media screen and (max-width: 850px) {
      transform: scale(0.5);
      transform-origin: 50% 0 0;
    }
    @media screen and (max-width: 730px) {
      transform: scale(0.4);
      transform-origin: 50% 0 0;
    }
    @media screen and (max-width: 580px) {
      transform: scale(0.3);
      transform-origin: 50% 0 0;
    }
    @media screen and (max-width: 450px) {
      transform: scale(0.25);
      transform-origin: 50% 0 0;
    }
    @media screen and (max-width: 374px) {
      transform: scale(0.21);
      transform-origin: 50% 0 0;
    }
  }

  .page {
    width: $pageWidth;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    padding: 40px 25px;
    margin-bottom: 10px;
    min-height: 1254px;
    position: relative;
    text-align: justify;
    @media screen and (max-width: 1270px) {
      border: 0;
    }
  }
}
