@import "../utils/vars";
@import "../utils/mixins";

app-admin-dashboard,
app-admissions,
app-patients-admission-docs,
app-patient-doc-period,
app-incidents,
app-patients-incident-report,
app-patients,
app-assigned-slide,
app-medical-documents-history,
app-timesheets,
app-medical {
  .btn {
    @media screen and (max-width: 600px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .list {
    &__head {
      @media screen and (max-width: 1600px) {
        min-width: 157px;
        display: flex;
        width: 100%;
        background-position: 100% 12px;
      }
    }
  }

  .slide-animate {
    z-index: 999;

    .slide-container {
      position: fixed;
      z-index: 55;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: hidden;
      width: 100%;
      height: -webkit-fill-available;

      &__overlay {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 54;
        background: rgba(0, 0, 0, 0.3);
        transition: $transition;
      }

      &__title {
        font-size: 16px;
        line-height: 22px;
        font-family: $openSansSemiBold;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #494A58;
        margin-bottom: 28px;
      }

      &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        z-index: 10;
        right: 0;
        top: 0;
        bottom: 0;
        width: 544px;
        height: 100%;
        background: $white-color;
        transform: translateX(544px);
        padding: 36px 15px 30px 30px;
        @media screen and (max-width: 767px) {
          padding-left: 15px;
        }
        @media screen and (max-width: 530px) {
          width: 100%;
          transform: translateX(100%);
        }

        .close {
          position: absolute;
          right: 20px;
          z-index: 20;
          top: 20px;
          color: #9D9EA8;
          cursor: pointer;
          padding-left: 5px;
          padding-right: 5px;
          background-color: $white-color;
        }
      }

      &__foot {
        padding: 40px 0 18px 0;
        border-top: 1px solid #E8E9F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 767px) {
          padding: 20px 0 18px 0;
        }

        .cancel {
          font-family: $openSansSemiBold;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 1;
          color: $violet;
          transition: $transition;

          &:hover {
            color: $violet-hover;
          }
        }
      }

      &__content {
        height: auto !important;
        width: 100% !important;
        opacity: 1 !important;
        padding-left: 0 !important;
        align-items: flex-start !important;
        flex-direction: column;
        @media screen and (max-width: 767px) {
          padding-right: 0;
        }

        &.scroll-enable {
          height: 100vh !important;
          overflow: auto !important;
        }
      }

      &__row {
        height: 100%;
        overflow-y: auto;
        @include scrollbar($scrollBarColor, transparent);
      }

      &.show {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .slide-container {
          &__body {
            transform: translateX(0);
          }
        }
      }
    }
  }

  .form-group {
    label {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: background-color ease 0.15s;
      font-family: $openSansRegular;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 1;
      color: #4C5560;
      padding: 8px 12px;

      &:before {
        content: '';
        -webkit-appearance: none;
        position: relative;
        display: inline-block;
        cursor: pointer;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        border: 1px solid #c8c8de;
        flex-shrink: 0;
      }

      &:hover {
        background-color: #f8f8fb;

        &:before {
          border: 1px solid #ABACBB;
        }
      }

      span {
        display: block;
      }
    }

    input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;

      &:checked {
        + label {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 18px;
            width: 4px;
            height: 7px;
            border: solid $violet;
            border-width: 0 2px 2px 0;
            transition: initial !important;
            transform: translateY(-50%) rotate(41deg) !important;
          }

          &:before {
            background-color: #e7e8f8;
            border: 1px solid $violet !important;
          }
        }
      }

      &:disabled {
        + label {
          &:after {
            border-color: $grey-light;
          }

          &:before {
            border-color: $grey-light !important;
          }
        }
      }
    }
  }

  .underline-link {
    font-size: 12px;
    color: $violet;
    position: relative;
    padding-right: 6px;
    text-decoration: underline;
    transition: $transition;

    &:hover {
      text-decoration: none;
    }

    &.pr20 {
      padding-right: 20px;
    }
  }

  .search-users {
    width: 100%;
    position: relative;

    &__label {
      display: block;
      width: 100%;
      margin-bottom: 8px;

      input {
        width: 100%;
        background: url("/assets/img/icons/search.svg") no-repeat 10px 10px;
        padding-left: 32px;
      }
    }

    &__drop {
      background-color: $white-color;
      border: 1px solid #CFD0DE;
      border-radius: 3px;
      padding: 4px 0;
      margin-bottom: 20px;
      max-height: 270px;
      overflow-y: auto;
      min-height: 100px;
      @include scrollbar($scrollBarColor, transparent);
    }

    &__selected {
      &--item {
        display: flex;
        align-items: center;
        font-family: $openSansRegular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1;
        color: #4C5560;
        padding-bottom: 16px;

        button {
          display: inline-block;
          color: $red-color;
          margin-right: 10px;
          transition: $transition;

          &:hover {
            color: $red-color-hover;
          }
        }
      }
    }
  }
}

app-assigned-slide {
  .form-group {
    label {
      span {
        display: block;
        padding-left: 10px;
      }
    }
  }
}
