@import "../utils/vars";

app-patients-red-flags,
app-red-flags {
  div {
    &[permission-disabled="true"] {
      .ng-select {
        .ng-arrow-wrapper {
          display: none;
        }
      }
    }
  }
}
