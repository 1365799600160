@import "../utils/vars";

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  line-height: 1.4;
  overflow: hidden;
  font-family: $openSansRegular;
  background-color: $white-color;
  min-width: 320px;

  &.fixed {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

a:focus {
  outline: none;
}

a::-moz-focus-inner {
  border: 0;
}

button:focus {
  outline: none;
}

button::-moz-focus-inner {
  border: 0;
}

input[type="submit"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner {
  border: 0;
}

input[type="submit"]:focus,
input[type="button"]:focus {
  outline: none;
}

a,
a:visited,
a:focus,
a:active,
a:hover {
  outline: 0;
}

a,
img {
  display: inline-block;
}

p {
  margin: 0;
  padding: 0;
}

a, button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  font-weight: normal;
}


[permission-disabled] {
  pointer-events: none !important;
 /* color: $grey-light !important;*/

  .slide-container__control {
    background-color: $disableButtonBackground !important;

    i {
      color: white !important;
    }
  }

  i {
    color: $grey-light !important;
  }
}

button[permission-disabled] {
  opacity: 0.5;
  background-color: #B1B2BC !important;
  color: white !important;

  i {
    color: white !important;
  }
}
