@import "../utils/vars";
@import "../utils/mixins";

.switch {
  display: inline-flex;
  align-items: center;

  &__item {
    border: 1px solid #C8C8DE;
    border-radius: 4px;
    padding: 6px 16px;
    background-color: #F8F8FB;
    color: #4C5560;
    text-align: center;
    font-family: $openSansRegular;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    position: relative;
    transition: $transition;
    cursor: pointer;

    &:first-child {
      z-index: 1;
    }

    &:last-child {
      left: -5px;

      &.active {
        z-index: 2;
      }
    }

    &:hover {
      color: $violet-hover;
    }

    &.active {
      border-color: $violet;
      color: $violet;
    }
  }
}
