@import "../utils/vars";

.toast-container {
  @media screen and (max-width: 600px) {
    left: 16px;
    right: 16px;
  }

  .ngx-toastr {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 380px;
    transition: $transition;
    padding: 16px 32px 16px 76px;
    border-radius: 8px;
    box-shadow: 0 2px 9px 1px rgba(48, 51, 64, 0.10);
    background: $white-color url("../../../assets/img/notifications/icon-info.svg") no-repeat 16px 16px;
    background-size: 48px;
    min-height: 80px;
    margin-bottom: 8px;
    @media screen and (max-width: 600px) {
      max-width: 100%;
      padding: 16px 48px 16px 76px;
    }

    &:hover {
      box-shadow: 0 2px 9px 1px rgba(48, 51, 64, 0.10);
    }

    &.toast-warning {
      background: $white-color url("../../../assets/img/notifications/icon-warning.svg") no-repeat 16px 16px;
      background-size: 48px;

      .toast-progress {
        background-color: #BF7D0A;
      }
    }
  }

  .toast-close-button {
    position: absolute;
    width: 20px;
    height: 20px;
    background: url("../../../assets/img/notifications/close-notification.svg") no-repeat center;
    background-size: 16px;
    right: 8px;
    top: 8px;
    transition: $transition;
    @media screen and (max-width: 600px) {
      width: 48px;
      height: 48px;
      right: 0;
      top: 0;
    }

    &:hover {
      opacity: $hoverOpacity;
    }

    span {
      display: none;
    }
  }

  .toast-message {
    color: #2B303D;
    font-family: $openSansRegular;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.07px;

    strong {
      font-family: $openSansSemiBold;
    }
  }

  .toast-progress {
    height: 3px;
    background-color: #7C81CF;
    opacity: 1;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
