@keyframes bounceLeft {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(30px);
  }
  60% {
    transform: translateX(15px);
  }
}

@keyframes bouncingLoader {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.25;
  }
}

@keyframes leftRight {
  0% {
    left: 0;
    transform: translateX(0);
  }
  100% {
    left: 100%;
    transform: translateX(-12px);
  }
}
