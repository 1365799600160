/* MEDIA */
@mixin breakpoint($point) {
  @if $point == laptop {
    @media (max-width: 1460px) {
      @content;
    }
  } @else if $point == tablet {
    @media (max-width: 1023px) {
      @content;
    }
  } @else if $point == mobile-l {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point == mobile-m {
    @media (max-width: 423px) {
      @content;
    }
  } @else if $point == mobile-s {
    @media (max-width: 375px) {
      @content;
    }
  }
}

/* COMMON */

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    opacity: 1;
    @content;
  }

  &:-moz-placeholder {
    opacity: 1;
    @content;
  }

  &:-ms-input-placeholder {
    opacity: 1;
    @content;
  }
}

@mixin scrollbar($color, $color-line) {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    cursor: pointer;
  }

  &::-webkit-scrollbar:vertical {
    width: 3px;
    cursor: pointer;
  }

  &::-webkit-scrollbar:horizontal {
    height: 3px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color;
    border: none;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-line;
    cursor: pointer;
  }
}

@mixin scrollbarWithWidth($color, $color-line, $width) {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    cursor: pointer;
    transition: all ease 0.15s;
    border-radius: 2px;
  }

  &::-webkit-scrollbar:vertical {
    width: $width;
    cursor: pointer;
    transition: all ease 0.15s;
    border-radius: 2px;
  }

  &::-webkit-scrollbar:horizontal {
    height: $width;
    cursor: pointer;
    transition: all ease 0.15s;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color;
    border: none;
    cursor: pointer;
    transition: all ease 0.15s;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-line;
    cursor: pointer;
    transition: all ease 0.15s;
    border-radius: 2px;
  }
}
