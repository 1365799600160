@import "../utils/vars";
@import "../utils/mixins";

.faq {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: calc(100% - 50px);
  background-color: $white-color;
  box-shadow: 0 2px 12px rgba(46, 55, 65, 0.07);
  border-radius: 3px;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    box-shadow: none;
    border-radius: 0;
  }

  app-faq-accordion {
    &:last-of-type {
      .faq-accordion {
        &__body,
        &__head {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }

  &__nav {
    width: 240px;
    flex-shrink: 0;
    background-color: #FBFBFF;
    border-right: 1px solid #E8E9F4;
    padding: 20px 0 30px 0;
    overflow-x: hidden;
    overflow-y: auto;
    @include scrollbar($scrollBarColor, transparent);
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0 0 12px 0;
      background-color: #f2f2f7;
      border: 0;
      position: relative;
    }

    > ul {
      width: 240px;
      padding: 0;
      margin: 0;
      z-index: 999;
      @media screen and (max-width: 767px) {
        display: none;
        position: absolute;
        top: 46px;
        right: 0;
        width: calc(100% - 61px);
        background-color: $white-color;
        border: 1px solid #E8E9F4;
        box-shadow: 0 2px 12px rgba(46, 55, 65, 0.07);
        border-radius: 3px;
        max-height: 450px;
        overflow-y: auto;
        @include scrollbar($scrollBarColor, transparent);
      }

      > li {
        padding: 16px 0 16px 40px;
        margin: 0;
        border-bottom: 1px solid #E8E9F4;
        @media screen and (max-width: 767px) {
          padding-left: 24px;
        }

        &:last-child {
          border-bottom: 0;
        }

        > a {
          display: inline-block;
          padding-left: 18px;
          position: relative;
          font-family: $openSansSemiBold;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 120%;
          color: #4C5560;
          transition: $transition;
          text-transform: capitalize;
          @media screen and (max-width: 767px) {
            padding-left: 0;
          }

          &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 5px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #4C5560;
            transition: $transition;
            @media screen and (max-width: 767px) {
              display: none;
            }
          }

          &:hover {
            color: $violet;

            &::before {
              background-color: $violet;
            }
          }
        }

        &.active {
          > a {
            color: $violet;

            &::before {
              background-color: $violet;
            }
          }
        }

        ul {
          margin: 0;
          padding: 0;

          li {
            margin: 0;
            padding: 0 0 12px 18px;
            @media screen and (max-width: 767px) {
              padding-left: 12px;
            }

            &:first-child {
              padding-top: 12px;
            }

            &:last-child {
              padding-bottom: 0;
            }

            a {
              display: inline-block;
              font-family: $openSansRegular;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 120%;
              color: #4C5560;
              transition: $transition;
              text-transform: capitalize;

              &:hover {
                color: $violet;
              }
            }

            &.active {
              > a {
                color: $violet;
              }
            }
          }
        }
      }
    }

    &.active {
      > ul {
        @media screen and (max-width: 767px) {
          display: block;
        }
      }

      .faq {
        &__section {
          label {
            &::before {
              border-bottom: 5px solid #9D9EA8;
              border-top: 0;
            }
          }
        }
      }
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 7px 20px 100px 80px;
    @include scrollbar(transparent, transparent);
    @media screen and (max-width: 1024px) {
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: calc(100% - 50px);
      padding-left: 15px;
      padding-right: 15px;
      box-shadow: 0 2px 12px rgba(46, 55, 65, 0.07);
      border-radius: 3px;
    }
  }

  &__title {
    font-family: $openSansBold;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 120%;
    color: #4C5560;
    text-transform: capitalize;
    padding: 0 0 8px 0;
  }

  &__sub-title {
    font-family: $openSansSemiBold;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #4C5560;
    text-transform: capitalize;
    padding: 0 0 8px 0;
  }

  &__item {
    width: 100%;
    max-width: 800px;
    padding-bottom: 8px;
    border-bottom: 1px solid #8E8E9F;
    padding-top: 28px;
  }

  &__sub-item {
    width: 100%;
    max-width: 800px;
    padding-top: 28px;

    &:last-of-type {
      margin-bottom: 0;

      app-faq-accordion {
        &:last-of-type {
          .faq-accordion {
            &__body,
            &__head {
              border-bottom: 1px solid transparent;
            }
          }
        }
      }
    }

    app-faq-accordion {
      &:last-of-type {
        .faq-accordion {
          &__body,
          &__head {
            border-bottom: 1px solid #E8E9F4;
          }

          &__head {
            &.active {
              border-bottom: 1px solid transparent;
            }
          }
        }
      }
    }

    &.last {
      border-bottom: 1px solid #8E8E9F;
    }
  }

  &__section {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
    }

    span {
      display: inline-block;
      font-family: $openSansSemiBold;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #9D9EA8;
      padding-right: 16px;
    }

    label {
      display: block;
      width: 100%;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        right: 16px;
        top: 16px;
        display: inline-block;
        border: 5px solid transparent;
        border-top: 5px solid #9D9EA8;
      }

      input {
        background-color: transparent;
        cursor: pointer;
        width: 100%;
        font-family: $openSansRegular;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #363636;
        text-transform: capitalize;

        &:focus,
        &:hover,
        &:active {
          border-color: #CFD0DE !important;
          box-shadow: none;
        }
      }
    }
  }
}

.faq-accordion {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    cursor: pointer;
    border-bottom: 1px solid #E8E9F4;

    span {
      display: inline-block;
      font-family: $openSansSemiBold;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      color: #8E8E9F;
    }

    i {
      display: inline-block;
      color: #8E8E9F;
      font-size: 10px;
    }

    &:hover {
      span,
      i {
        color: #4C5560;
      }
    }

    &.active {
      border-bottom: 1px solid transparent;

      span,
      i {
        color: #4C5560;
      }

      i {
        transform: rotate(180deg);
      }
    }
  }

  &__body {
    border-bottom: 1px solid #E8E9F4;
    padding: 0 0 20px 0;

    h1, h2, h3, h4, h5, h6 {
      padding: 0 0 12px 0;
      margin: 0;
      font-family: $openSansSemiBold;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 120%;
      color: #4C5560;
    }

    p {
      padding: 0 0 12px 0;
      font-family: $openSansRegular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #4C5560;
    }

    strong {
      font-family: $openSansSemiBold;
    }

    a {
      color: $violet;

      &:hover {
        text-decoration: underline;
      }
    }

    i {
      font-style: italic;
    }

    ul {
      padding: 0 0 16px 20px;
      margin: 0;

      li {
        padding: 0 0 8px 14px;
        font-family: $openSansRegular;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #4C5560;
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 5px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #4C5560;
        }
      }
    }

    ol {
      padding: 0 0 16px 33px;
      margin: 0;

      li {
        padding: 0 0 8px 3px;
        font-family: $openSansRegular;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #4C5560;
        position: relative;
        list-style-type: decimal;
      }
    }

    .accent {
      background-color: rgba(100, 105, 183, 0.1);
      border: 1px solid rgba(100, 105, 183, 0.5);
      border-radius: 3px;
      padding: 20px;

      h1, h2, h3, h4, h5, h6 {
        font-family: $openSansSemiBold;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        color: #4C5560;
        padding: 0 0 8px 0;
        margin: 0;
      }

      p {
        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 24px 0;
    @media screen and (max-width: 1100px) {
      flex-direction: column;
      justify-content: flex-start;
      padding-bottom: 4px;
    }

    img,
    .video {
      display: block;
      border-radius: 3px;
      overflow: hidden;
      width: calc(50% - 10px);
      height: 202px;
      @media screen and (max-width: 1100px) {
        margin-bottom: 20px;
        width: 100%;
        max-width: 338px;
      }
      @media screen and (max-width: 767px) {
        max-width: 268px;
        height: 151px;
      }
    }

    .video {
      position: relative;

      button {
        cursor: pointer;
        width: 52px;
        height: 52px;
        position: absolute;
        z-index: 5;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: url("../../../assets/img/faq/play-circle.svg") no-repeat center;
        transition: $transition;
        @media screen and (max-width: 767px) {
          width: 40px;
          height: 40px;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
