@import "../utils/vars";

.create-notes {
  width: 100%;

  &__form {
    &-item {
      margin-bottom: 30px;

      textarea {
        width: 100%;
        min-height: 150px;
        max-height: 150px;
        background-color: $white-color;
        border: 1px solid #CFD0DE;
        box-sizing: border-box;
        border-radius: 3px;
        resize: none;
        padding: 15px;
        transition: $transition;

        &:hover,
        &:focus {
          border-color: #ABACBB;
        }
      }
    }

    &-title {
      font-family: $openSansSemiBold;
      font-size: 13px;
      line-height: 14px;
      letter-spacing: 0.005em;
      color: #9D9EA8;
      margin-bottom: 9px;
    }

    &-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media screen and (max-width: 600px) {
        justify-content: space-between !important;
        padding: 0 40px;
      }

      .cancel {
        margin-right: 20px;
        font-size: 14px;
        line-height: 19px;
        font-family: $openSansSemiBold;
        color: $violet;
      }

      .btn {
        padding-left: 20px;
        padding-right: 20px;

        &[disabled] {
          opacity: .5;
          cursor: default;

          &:hover {
            background-color: $violet;
          }
        }
      }
    }
  }
}

app-admissions,
app-patients-docs,
app-incidents,
app-incidents-attachments,
app-patients-incident-report,
app-notes,
app-patient-doc-period,
app-patients,
app-documents-history,
app-red-flags,
app-patients-red-flags,
app-medical,
app-medical-documents-history {
  .create-notes {
    &__form {
      &-item {
        .select {
          flex-direction: column;
          width: 100%;
          display: flex;
          position: relative;

          &:after {
            content: '';
            display: block;
            position: absolute;
            right: 10px;
            top: 39px;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #9d9ea8;
          }

          span {
            font-family: $openSansSemiBold;
            font-size: 13px;
            line-height: 14px;
            letter-spacing: 0.005em;
            color: #9D9EA8;
            margin-bottom: 9px;
          }
        }

        &.error {
          textarea,
          select {
            border-color: $red-color;
          }
        }
      }
    }
  }
}

app-admissions,
app-patients-docs,
app-incidents,
app-incidents-attachments,
app-patients-incident-report,
app-patients,
app-documents-history,
app-red-flags,
app-patients-red-flags,
app-medical,
app-medical-documents-history {
  .create-notes {
    &__form {
      &-item {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}

app-admissions,
app-incidents,
app-incidents-attachments,
app-patients-incident-report,
app-patients,
app-red-flags,
app-patients-red-flags,
app-red-flag-notes,
app-medical,
app-documents-history,
app-medical-documents-history {
  .create-notes {
    &__form {
      &-item {
        .error-msg {
          display: block !important;
        }
      }
    }
  }
}

app-admissions,
app-incidents,
app-incidents-attachments,
app-patients-incident-report,
app-notes,
app-medical,
app-medical-documents-history,
app-patients,
app-patients-red-flags,
app-red-flags {
  .create-notes {
    &__form {
      &-btn {
        .btn {
          @media screen and (max-width: 600px) {
            width: 135px;
          }
        }
      }
    }
  }
}

app-admission-notes,
app-admissions,
app-incidents,
app-incidents-attachments,
app-patients-incident-report,
app-red-flags,
app-patients-red-flags,
app-medical,
app-red-flag-notes {
  .create-notes {
    &__form {
      &-item {
        textarea {
          &.error {
            border-color: $red-color;
          }
        }
      }
    }
  }
}

app-admission-notes,
app-admissions,
app-patient-doc-period,
app-notes,
app-red-flags,
app-patients-red-flags,
app-medical,
app-medical-documents-history,
app-red-flag-notes {
  .create-notes {
    &:disabled {
      opacity: .5;
      cursor: default;

      &:hover {
        background-color: $violet;
      }
    }
  }
}

app-notes,
app-patients,
app-documents-history,
app-medical,
app-medical-documents-history {
  .create-notes {
    &__form {
      &-item {
        textarea {
          &.error {
            border-color: $red-color;
          }
        }
      }
    }
  }
}

app-incidents-attachments,
app-documents-history,
app-medical,
app-medical-documents-history {
  .create-notes {
    &__form {
      &-btn {
        &.save {
          @media screen and (max-width: 600px) {
            width: 135px;
          }
        }
      }
    }
  }
}

app-admission-notes,
app-notes,
app-red-flag-notes {
  .create-notes {
    &__form {
      &-title {
        display: block;
      }
    }
  }
}

app-admission-notes,
app-red-flag-notes {
  .create-notes {
    max-width: 600px;

    &__form {
      .select {
        flex-direction: column;
        width: 100%;
        display: flex;
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          right: 10px;
          top: 39px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid #9d9ea8;
        }

        span {
          font-family: $openSansSemiBold;
          font-size: 13px;
          line-height: 14px;
          letter-spacing: 0.005em;
          color: #9D9EA8;
          margin-bottom: 9px;
        }
      }

      .error {
        textarea,
        select {
          border-color: $red-color;
        }
      }
    }
  }
}

app-patients-docs {
  .create-notes {
    &__form {
      &-item {
        &.error {
          .error-msg {
            display: block !important;
          }
        }
      }
    }
  }
}
