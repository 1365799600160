@import "../../../assets/styles/utils/vars";
@import "../../../assets/styles/utils/mixins";

.by-date {
  display: flex;
  position: relative;
  z-index: 10;
  padding-bottom: 20px;
  margin-right: 20px;
  @media screen and (max-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
  }

  input {
    height: 32px;
    background: #F2F2F8;
    border: 1px solid #CFD0DE;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 13px;
    line-height: 18px;
    color: #4C5560 !important;
    padding: 0 29px 0 10px;
    font-family: $openSansSemiBold;
    @media screen and (max-width: 767px), (max-height: 767px) {
      font-size: 14px;
    }

    &:hover {
      border-color: #ABACBB;
      box-shadow: none;
    }
  }

  &.showCalendar {
    input {
      border-color: $violet-hover;
    }

    .flatpickr-calendar {
      &.inline {
        display: block;
        box-shadow: 0 2px 12px rgba(46, 55, 65, 0.16);
      }
    }
  }

  .icon-calendar {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #9D9EA8;
    font-size: 13px;
  }

  .flatpickr-input {
    &.active {
      + span {
        background: url("/assets/img/icons/arrow-top.svg") no-repeat 100% 8px;
      }
    }
  }

  .toggle-date {
    display: block;
    padding: 4px 22px 4px 7px;
    font-family: $openSansSemiBold;
    font-size: 13px;
    font-weight: normal;
    color: #4c5560;
    cursor: pointer;
    background: url("/assets/img/icons/arrow-bottom.svg") no-repeat calc(100% - 7px) 12px;
    position: relative;
    top: -3px;
    transition: $transition;
    border-radius: 3px;
    @media screen and (max-width: 1200px) {
      background-position: calc(100% - 16px) 50%;
      order: 1;
      width: 100%;
      padding: 15px;
      border-bottom: 1px solid #e8e9f4;
      top: 0;
    }

    &:hover {
      background-color: #eaeaf0;
      @media screen and (max-width: 1200px) {
        background-color: $white-color;
      }
    }

    &.showCalendar {
      @media screen and (max-width: 1200px) {
        background-position: calc(100% - 16px) 50%;
      }
    }
  }

  .flatpickr-calendar {
    box-shadow: none;
    border-radius: 3px;
    background: $white-color;
    max-width: 290px;
    @media screen and (max-width: 1200px) {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    &.inline {
      position: absolute;
      top: 100%;
      display: none;
      @media screen and (max-width: 1200px) {
        order: 5;
      }
    }

    &.arrowTop {
      &:before,
      &:after {
        display: none;
      }
    }
  }
}

.calendar {
  position: relative;

  label {
    position: relative;

    i {
      position: absolute;
      right: 12px;
      top: 34px;
      font-size: 14px;
      color: #9d9ea8;
    }
  }

  .flatpickr-calendar {
    display: none;
    position: absolute !important;
    z-index: 9999 !important;
    top: calc(100% + 15px);
    max-width: 290px;
  }

  &.showCalendar {
    .flatpickr-calendar {
      display: block;
    }
  }

  &.authorized {
    margin-right: 30px;
    @media screen and (max-width: 600px) {
      margin-right: 0;
      width: 89%;
    }

    label {
      padding-right: 0;
      margin-bottom: 0;
      position: relative;

      i {
        position: absolute;
        right: 12px;
        top: 11px;
        font-size: 14px;
        color: #9d9ea8;
      }
    }

    input {
      min-width: 185px;
    }
  }
}

.numInputWrapper {
  &:hover {
    background: 0 0;
  }
}

.flatpickr-current-month {
  font-family: $openSansSemiBold;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #9d9ea8;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  padding: 0;

  span {
    &.cur-month {
      margin-left: 0;
      display: block;
      color: $black-color;

      &:hover {
        background: 0 0;
      }
    }
  }

  input {
    &.cur-year {
      color: $black-color !important;
      font-family: $openSansSemiBold;
      font-weight: normal;
      font-size: 12px;

      &:hover {
        background: 0 0;
      }
    }
  }

  .numInputWrapper {
    width: 7ch;
  }
}

.flatpickr-months {
  justify-content: center;
  width: 180px;
  margin: 0 auto 11px auto;
  height: 25px;

  .flatpickr-month {
    height: 45px;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    padding: 0;
    height: auto;
    color: $violet;
    fill: transparent;
    font-size: 0;
    position: relative;

    &:after {
      font-family: 'icomoon', sans-serif !important;
      content: '\e908';
      position: absolute;
      top: 11px;
      display: inline-block;
      font-size: 10px;
      color: $violet;
    }

    &:hover {
      &:after {
        color: $violet-hover;
      }
    }
  }

  .flatpickr-prev-month {
    &:after {
      transform: scaleX(-1);
    }
  }

  .flatpickr-next-month {
    &:after {
      transform: scaleX(1);
      right: 7px;
    }
  }
}

.flatpickr-rContainer {
  width: 100%;
}

.flatpickr-innerContainer {
  border-top: 1px solid #e8e9f4;
  padding: 8px 15px 15px 15px;
  @media screen and (max-width: 1420px) {
    border: 0;
  }
  @media screen and (max-height: 550px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .flatpickr-day {
    @media screen and (max-height: 550px) {
      //margin: 0 4px 4px 4px;
    }
  }
}

.flatpickr-days {
  width: 100%;
  padding-top: 12px;
  overflow: visible;
}

.dayContainer {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 1420px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
    grid-gap: 4px;
  }
  @media screen and (max-width: 400px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(27px, 1fr));
    grid-gap: 4px;
  }
}

.flatpickr-weekdaycontainer {
  span {
    &.flatpickr-weekday {
      font-family: $openSansSemiBold;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.5;
      color: #9d9ea8;
    }
  }
}

.clear-all,
.delete {
  font-family: $openSansSemiBold;
  font-size: 13px;
  font-weight: normal;
  color: $violet;
  transition: $transition;
  vertical-align: middle;
  padding-left: 23px;
  background: url("/assets/img/icons/clear.svg") no-repeat 0 5px;
  display: inline-block;
  padding-top: 3px;

  &:hover {
    color: $violet-hover;
    background: url("/assets/img/icons/clear-hover.svg") no-repeat 0 5px;
  }
}

.delete {
  @media screen and (max-width: 600px) {
    padding-top: 0;
    background-position: 0 2px;
  }

  &:hover {
    @media screen and (max-width: 600px) {
      background-position: 0 2px;
    }
  }
}

.flatpickr-day {
  height: 30px;
  width: 30px;
  max-width: 30px;
  max-height: 30px;
  line-height: 30px;
  font-size: 12px;
  font-family: $openSansSemiBold;
  font-weight: normal;
  color: #4c5560;
  margin: 0 2px 10px 2px;
  transition: $transition;
  @media screen and (max-width: 1420px) {
    margin: 0 4px 10px 4px;
    width: 25px;
    height: 25px;
    max-width: 25px;
    max-height: 25px;
    line-height: 25px;
  }

  &.today {
    border-color: #e8e9f4;
    transition: $transition;

    &:hover {
      background-color: $violet-hover;
      border-color: $violet-hover;
      color: $white-color;
    }
  }

  &.disabled,
  &.disabled:hover,
  &.prevMonthDay,
  &.nextMonthDay,
  &.notAllowed,
  &.notAllowed.prevMonthDay,
  &.notAllowed.nextMonthDay {
    font-family: $openSansSemiBold;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.5;
    color: #cfcfcf;
    height: 30px;
    width: 30px;
    max-width: 30px;
    max-height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1420px) {
      width: 25px;
      height: 25px;
      max-width: 25px;
      max-height: 25px;
      line-height: 25px;
    }
  }

  &.inRange,
  &.prevMonthDay.inRange,
  &.nextMonthDay.inRange,
  &.today.inRange,
  &.prevMonthDay.today.inRange,
  &.nextMonthDay.today.inRange,
  &:hover,
  &.prevMonthDay:hover,
  &.nextMonthDay:hover,
  &:focus,
  &.prevMonthDay:focus,
  &.nextMonthDay:focus {
    background-color: #f2f2f8;
    border-color: transparent;
  }

  &.inRange {
    box-shadow: -5px 0 0 #e0e2fe, 5px 0 0 #e0e2fe;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      bottom: -1px;
      width: 19px;
      left: 100%;
      background-color: #e0e2fe;
    }
  }

  &.selected.startRange {
    position: relative;
    z-index: 10;

    + .flatpickr-day.inRange {
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 100%;
        width: 25px;
        top: -1px;
        bottom: -1px;
        background-color: #e0e2fe;
      }
    }
  }

  &.selected.endRange {
    position: relative;
    z-index: 100;
    border-radius: 50%;
  }

  &.selected.startRange,
  &.startRange.startRange,
  &.endRange.startRange {
    border-radius: 50%;

    + .endRange:not(:nth-child(7n+1)),
    + .endRange:not(:nth-child(7n+1)),
    + .endRange:not(:nth-child(7n+1)) {
      box-shadow: none;
    }
  }

  &.selected.endRange,
  &.startRange.endRange,
  &.endRange.endRange {
    border-radius: 50%;
  }

  &.startRange.endRange,
  &.endRange.endRange {
    position: relative;
    z-index: 10;
  }

  &.selected,
  &.startRange,
  &.endRange,
  &.selected.inRange,
  &.startRange.inRange,
  &.endRange.inRange,
  &.selected:focus,
  &.startRange:focus,
  &.endRange:focus,
  &.selected:hover,
  &.startRange:hover,
  &.endRange:hover,
  &.selected.prevMonthDay,
  &.startRange.prevMonthDay,
  &.endRange.prevMonthDay,
  &.selected.nextMonthDay,
  &.startRange.nextMonthDay,
  &.endRange.nextMonthDay {
    background-color: $violet;
    border-color: $violet;
  }

  &.inRange,
  &.prevMonthDay.inRange,
  &.nextMonthDay.inRange,
  &.today.inRange,
  &.prevMonthDay.today.inRange,
  &.nextMonthDay.today.inRange,
  &:focus,
  &.prevMonthDay:focus,
  &.nextMonthDay:focus {
    background-color: #e0e2fe;
  }

  &.startRange.startRange,
  &.endRange.startRange {
    &:hover {
      z-index: 100;

      + .flatpickr-day.inRange {
        position: relative;
        z-index: 10;

        &:after {
          content: '';
          display: block;
          position: absolute;
          z-index: -10;
          right: 100%;
          top: -1px;
          bottom: -1px;
          width: 25px;
          background-color: #e0e2fe;
        }
      }
    }
  }
}

.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.nextMonthDay:focus {
  border-radius: 0;
}

.flatpickr-day.nextMonthDay.endRange.inRange {
  background-color: $violet !important;
  border-color: $violet;
  border-radius: 50%;

  &:before {
    z-index: -1;
    left: initial;
    right: 50%;
  }
}

.flatpickr-day.prevMonthDay.startRange,
.flatpickr-day.prevMonthDay.endRange,
.flatpickr-day.prevMonthDay.selected.endRange,
.flatpickr-day.prevMonthDay.selected.startRange,
.flatpickr-day.endRange,
.flatpickr-day.prevMonthDay.selected.startRange.endRange,
.flatpickr-day.prevMonthDay.startRange.endRange,
.flatpickr-day.nextMonthDay.selected.startRange.inRange,
.flatpickr-day.nextMonthDay.startRange,
.flatpickr-day.nextMonthDay.selected.endRange.inRange,
.flatpickr-day.nextMonthDay.selected.endRange,
.flatpickr-day.nextMonthDay.endRange,
.flatpickr-day.nextMonthDay.startRange.endRange,
.flatpickr-day.nextMonthDay.endRange.endRange {
  border-radius: 50%;
  background-color: $violet !important;
  border-color: $violet;
}

.flatpickr-day.prevMonthDay.inRange {
  border-radius: 0 !important;
}

.flatpickr-day.nextMonthDay.endRange.inRange:before {
  display: none;
}

.filters-datepicker::-webkit-input-placeholder {
  color: #4C5560 !important;
  opacity: 1 !important;
}

.filters-datepicker::-moz-placeholder {
  color: #4C5560 !important;
  opacity: 1 !important;
}

.filters-datepicker:-ms-input-placeholder {
  color: #4C5560 !important;
  opacity: 1 !important;
}

.filters-datepicker:-moz-placeholder {
  color: #4C5560 !important;
  opacity: 1 !important;
}

.flatpickr-calendar {
  &.hasTime {
    .flatpickr-time {
      height: 30px;
      line-height: 30px;
      font-size: 12px;

      .numInputWrapper {
        height: 30px;
      }

      input {
        font-size: 12px;
      }
    }

    .send-button {
      width: 100%;
      height: 30px;
      color: white;
      background-color: $violet;
      transition: $transition;

      &:hover {
        background-color: $violet-hover;
      }
    }

    .numInput {
      select {
        background-color: $violet;
      }
    }
  }
}
