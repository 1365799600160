// plugins
@import "@ng-select/ng-select/themes/default.theme.css";
@import "flatpickr/dist/flatpickr.css";
@import '@angular/cdk/overlay-prebuilt.css';
@import 'ngx-toastr/toastr';

// end plugins
@import "./assets/styles/styles";

:root {
  --scrollbar-thickness: 10;
}
