@import "../utils/vars";
@import "../utils/mixins";

.edit-date {
  color: #4C5560;
  font-family: $openSansSemiBold;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  min-width: 65px;
  min-height: 17px;

  .custom-tooltip {
    &__text {
      cursor: pointer;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

.slide-animate {
  &.change-dates {
    width: 402px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 999;
    @media screen and (max-width: 500px) {
      width: 100%;
    }

    .slide-container {
      position: relative;

      &.show {
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
      }

      &__body {
        width: 402px;
        padding: 0 0 48px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: 500px) {
          width: 100%;
          padding-bottom: 20px;
        }
      }

      &__row {
        height: 100%;
        padding-bottom: 30px;
        overflow-y: auto;

        .close {
          position: initial !important;
          display: flex;
          justify-content: flex-end;
          padding: 12px 12px 28px 0;

          i {
            @media screen and (max-width: 621px) {
              color: #9D9EA8 !important;
            }
          }
        }
      }

      &__title {
        color: #4C5560;
        font-family: $openSansSemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 17px;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        padding: 0 30px 30px 30px;
        margin: 0;
        width: 100%;
        @media screen and (max-width: 500px) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      &__content {
        padding: 0;
      }
    }
  }
}

.change-dates {
  .flatpickr-wrapper {
    width: 100%;

    > input {
      width: 100%;
      border-radius: 3px;
      border: 1px solid #CFD0DE;
      background-color: $white-color;
      height: 36px;
      padding: 0 7px;
      font-family: $openSansRegular;
      color: #4c5560 !important;
      font-size: 13px;
      transition: $transition;

      &:hover {
        border-color: #ABACBB;
      }
    }
  }

  .flatpickr-calendar {
    max-width: inherit !important;
    display: block;
    height: 0;
    opacity: 0;
    transition: all ease 0.5s;
    overflow: hidden;
  }

  &__item {
    width: 100%;
    padding: 0 30px 26px 30px;
    @media screen and (max-width: 500px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__title {
    display: block;
    width: 100%;
    color: #9D9EA8;
    font-family: $openSansSemiBold;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 18px;
    padding: 0 0 5px 0;
    text-transform: capitalize;
  }

  &__foot {
    padding: 32px 30px 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media screen and (max-width: 500px) {
      padding: 12px 15px 0 15px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 30px;
      right: 30px;
      width: calc(100% - 60px);
      height: 1px;
      background-color: #E8E9F4;
      @media screen and (max-width: 500px) {
        width: calc(100% - 30px);
        left: 15px;
        right: 15px;
      }
    }

    .cancel {
      color: $violet;
      font-family: $openSansSemiBold;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      transition: $transition;

      &:hover {
        color: $violet-hover;
      }
    }
  }

  &__calendar {
    position: relative;

    .icon-calendar {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 11px;
      font-size: 14px;
      color: #9d9ea8;
      transition: $transition;

      &:hover {
        opacity: $hoverOpacity;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: -7px;
        right: -7px;
        top: -7px;
        bottom: -7px;
        background-color: transparent;
      }
    }

    &.show-calendar {
      .flatpickr-calendar {
        height: 340px;
        opacity: 1;
      }
    }
  }
}
