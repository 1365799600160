@import "../../../assets/styles/utils/vars";
@import "../../../assets/styles/utils/mixins";

.search-select {
  min-width: 186px;
  margin-right: 20px;
  background: transparent;
  height: 32px;
  border: 1px solid #CFD0DE;
  border-radius: 3px;
  background: url("/assets/img/icons/search.svg") no-repeat 10px 8px;
  padding-left: 29px;
  color: #b7bbbf;
  font-family: $openSansSemiBold;
  font-size: 13px;
  font-weight: normal;

  &:focus {
    box-shadow: none;
    border-color: $violet;
  }
}

.role-select {
  min-width: 148px;

  .ng-select-container {
    border: none;
    font-family: $openSansSemiBold;
    font-size: 13px;
    height: 26px;
    cursor: pointer !important;

    .ng-arrow-wrapper {
      background: url('/assets/img/icons/arrow-bottom.svg') no-repeat calc(100% - 10px) 16px;
      height: 100%;

      .ng-arrow {
        display: none;
      }
    }

    .ng-value-container {
      .ng-placeholder {
        color: #4c5560;
      }
    }

    &:hover {
      box-shadow: none;
      background-color: #eaeaf0;
    }

  }

  .ng-dropdown-panel {
    box-shadow: none;
    margin-right: 0;
    border-radius: 3px;

    &.ng-select-bottom {
      border-radius: 3px;
      border: none;
      box-shadow: $drop-box-shadow;
      background-color: $white-color;
      margin-top: 7px;
      @media screen and (max-width: 768px) {
        min-width: 300px;
      }
    }

    .ng-dropdown-panel-items {
      padding-top: 14px;
      padding-bottom: 6px;

      .ng-option {
        padding: 6px 0 6px 20px;
        font-family: $openSansRegular;
        font-size: 12px;
        margin-bottom: 10px;
        color: #4c5560;
        font-weight: normal;
        line-height: 1.33;
        transition: $transition;

        &:hover {
          color: darken(#4c5560, 10%);
        }

        &.ng-option-selected {
          background-color: transparent;
        }

        &.ng-option-marked {
          background-color: transparent;
        }
      }
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      .ng-arrow-wrapper {
        background: url('/assets/img/icons/arrow-top.svg') no-repeat calc(100% - 10px) 16px;
      }
    }
  }
}
