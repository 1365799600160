@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon.eot?ybarcn');
  src: url('/assets/fonts/icomoon.eot?ybarcn#iefix') format('embedded-opentype'),
  url('/assets/fonts/icomoon.ttf?ybarcn') format('truetype'),
  url('/assets/fonts/icomoon.woff?ybarcn') format('woff'),
  url('/assets/fonts/icomoon.svg?ybarcn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-message:before {
  content: "\e92e";
}

.icon-info-new:before {
  content: "\e92f";
}

.icon-info-icon:before {
  content: "\e92c";
}

.icon-refresh:before {
  content: "\e92d";
}

.icon-eye:before {
  content: "\e92b";
}

.icon-play:before {
  content: "\e929";
}

.icon-education:before {
  content: "\e92a";
}

.icon-file-cabinet:before {
  content: "\e928";
}

.icon-back-arrow:before {
  content: "\e927";
}

.icon-file-text1:before {
  content: "\e924";
}

.icon-mail:before {
  content: "\e925";
}

.icon-signature:before {
  content: "\e926";
}

.icon-patients:before {
  content: "\e923";
}

.icon-upload:before {
  content: "\e922";
}

.icon-folder:before {
  content: "\e91f";
}

.icon-icon_download:before {
  content: "\e920";
}

.icon-file:before {
  content: "\e921";
}

.icon-hospitals:before {
  content: "\e91a";
}

.icon-bag:before {
  content: "\e91b";
}

.icon-incidents0:before {
  content: "\e91c";
}

.icon-time:before {
  content: "\e91d";
}

.icon-close1:before {
  content: "\e91e";
}

.icon-resize-handle:before {
  content: "\e919";
}

.icon-color-picker:before {
  content: "\e918";
}

.icon-calendar:before {
  content: "\e917";
}

.icon-copied:before {
  content: "\e916";
}

.icon-send:before {
  content: "\e915";
}

.icon-copy:before {
  content: "\e913";
}

.icon-print:before {
  content: "\e914";
}

.icon-check1:before {
  content: "\e912";
}

.icon-users:before {
  content: "\e910";
}

.icon-dashboard:before {
  content: "\e911";
}

.icon-lock:before {
  content: "\e90f";
}

.icon-close:before {
  content: "\e90e";
}

.icon-edit-header:before {
  content: "\e90c";
}

.icon-log-out:before {
  content: "\e90d";
}

.icon-delete:before {
  content: "\e900";
}

.icon-minus-circle:before {
  content: "\e901";
}

.icon-arrow-bottom:before {
  content: "\e902";
}

.icon-check:before {
  content: "\e903";
}

.icon-plus:before {
  content: "\e904";
}

.icon-search:before {
  content: "\e905";
}

.icon-two-arrow:before {
  content: "\e906";
}

.icon-user:before {
  content: "\e907";
}

.icon-arrow-right:before {
  content: "\e908";
}

.icon-settings:before {
  content: "\e909";
}

.icon-edit:before {
  content: "\e90a";
}

.icon-nav:before {
  content: "\e90b";
}
