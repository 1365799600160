@import "../utils/vars";

app-new-medical-application,
app-new-employee-application {
  .form-tabs {
    width: 100%;
    padding: 0 14px;
    @media screen and (max-width: 960px) {
      display: none;
    }
    @media screen and (max-width: 374px) {
      padding: 0 5px;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-width: 170px;
      padding: 12px 5px;
      border: 1px solid #E8E9F4;
      font-family: $openSansSemiBold;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 131%;
      letter-spacing: 0.005em;
      color: #9d9ea8;
      cursor: pointer;
      transition: $transition;
      margin-bottom: -1px;
      border-radius: 6px 6px 0 0;
      @media screen and (max-width: 425px) {
        width: calc(50% - 4px);
        min-width: auto;
      }

      &:not(:last-child) {
        margin-right: 8px;
        @media screen and (max-width: 425px) {
          margin-right: 0;
        }
      }

      &:hover {
        color: $violet;
      }

      br {
        display: none;
        @media screen and (max-width: 374px) {
          display: inline-block;
        }
      }

      &.active {
        background-color: $white-color;
        border-bottom-color: $white-color;
        color: #4C5560;

        &:hover {
          color: #4C5560;
        }
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.8;
      }

      &.error {
        color: $red-color;
        border-color: $red-color;
      }
    }

    &__content {
      background-color: $white-color;
      padding: 30px;
      border-top: 1px solid #E8E9F4;
      @media screen and (max-width: 960px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &__container {
      max-width: 696px;
      margin: 0 auto;
      @media screen and (max-width: 960px) {
        max-width: 100%;
      }

      &.flex {
        display: flex;
        align-items: stretch;
        @media screen and (max-width: 425px) {
          justify-content: space-between;
        }
      }
    }
  }
}
