html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

* {
  padding: 0;
  margin: 0;
  outline: none;

  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }

  &:focus {
    outline: none;
  }
}

section, article, aside, details, figcaption, figure,
footer, header, menu, nav, section, blockquote {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
button,
textarea {
  box-shadow: none;

  &:focus {
    outline: none;
  }
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:focus {
    text-decoration: none;
    outline: none;
  }
}

img {
  border: 0
}

abbr,
acronym {
  border: 0
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  appearance: none;
}
