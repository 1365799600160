.dashboard-title {
  padding-top: 5px;
  padding-bottom: 31px;
  @media screen and (max-width: 767px) {
    padding-top: 0;
  }
}

app-admin-dashboard,
app-medical {
  .dashboard-title {
    padding-top: 0;
    padding-bottom: 0;
  }
}

app-system-setting {
  .dashboard-title {
    padding-bottom: 0;
  }
}
