@import "../utils/vars";

app-choosed-documents,
app-choosed-documents-admission {
  input {
    transition: $transition;

    &.error {
      border-color: $red-color;
      margin-top: 0;
    }
  }

  .choose-documents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 80px);
    min-height: calc(100vh - 80px);
    @media screen and (max-width: 1400px) {
      height: calc(100vh - 200px);
      min-height: calc(100vh - 200px);
    }
    @media screen and (max-width: 600px) {
      height: calc(100vh - 150px);
      min-height: calc(100vh - 150px);
    }

    h3 {
      text-transform: uppercase;
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 22px;
      font-family: $openSansSemiBold;
    }

    p {
      font-size: 12px;
      line-height: 18px;
      color: #9D9EA8;
      margin-bottom: 14px;
      margin-top: 20px;
      @media screen and (max-height: 900px) {
        margin-top: 0;
        margin-bottom: 14px;
      }
    }

    .search {
      margin-bottom: 30px;
      @media screen and (max-height: 767px) {
        margin-bottom: 0;
      }

      input {
        background: url("../../../assets/img/icons/search.svg") no-repeat 10px 10px;
        padding-left: 30px;
        color: #b7bbbf;
        font-family: $openSansSemiBold;
        font-size: 13px;
        font-weight: normal;
        border: 1px solid #CFD0DE;
        box-sizing: border-box;
        border-radius: 3px;
        width: 100%;
        @media screen and (max-height: 767px) {
          font-size: 14px;
        }
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }

    .message {
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .form-group {
      input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
      }

      label {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        cursor: pointer;
        flex-direction: row;
        justify-content: flex-start;
        transition: background-color ease 0.15s;
        font-size: 12px;
        line-height: 16px;
        color: #4C5560;
        padding-left: 0;
        padding-right: 0;

        &:before {
          content: '';
          -webkit-appearance: none;
          position: relative;
          display: inline-block;
          cursor: pointer;
          width: 16px;
          height: 16px;
          border-radius: 3px;
          border: 1px solid #c8c8de;
          flex-shrink: 0;
          margin-right: 10px;
        }
      }

      input:checked + label {
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 6px;
          width: 4px;
          height: 7px;
          border: solid $violet;
          border-width: 0 2px 2px 0;
          transition: initial !important;
          transform: translateY(-50%) rotate(41deg) !important;
        }

        &:before {
          background-color: #e7e8f8;
        }
      }
    }

    // layout
    &__header {
      width: 100%;
      flex-shrink: 0;
      @media screen and (max-width: 767px) {
        padding: 36px 0 0;
      }
    }

    &__list {
      height: 100%;
      overflow-x: auto;
      padding-bottom: 35px;

      &.full {
        max-height: 70px;
      }

      @media screen and (max-height: $iPhone5Height) {
        min-height: 230px;
      }
      @media only screen and (min-width: $iPhone5Height) and (orientation: landscape) {
        min-height: 65px;
      }

      &-item {
        margin-bottom: 16px;
      }
    }

    .next-btn-wrap {
      flex-shrink: 0;
      background: $white-color;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 0 0 0;
      z-index: 100;

      .btn {
        min-width: 124px;
      }
    }

    // end layout
    &-action {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100vh - 120px);
      min-height: calc(100vh - 120px);
      padding: 10px 0 0 0;
      @media screen and (max-width: 1400px) {
        height: calc(100vh - 200px);
        min-height: calc(100vh - 200px);
      }
      @media screen and (max-width: 600px) {
        height: calc(100vh - 150px);
        min-height: calc(100vh - 150px);
      }

      .back-btn-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-shrink: 0;
        cursor: pointer;
        padding-top: 4px;
        padding-bottom: 20px;

        span {
          font-size: 13px;
          line-height: 14px;
          font-family: $openSansSemiBold;
          color: $violet;
          transition: all 0.3s ease;

          &:hover {
            color: $violet-hover;
          }

          i {
            margin-right: 8px;
            font-size: 10px;
            line-height: 14px;
          }
        }
      }

      &__list {
        width: 100%;
        height: 100%;
        overflow-x: auto;
        padding-bottom: 35px;
        @media screen and (max-height: $iPhone5Height) {
          min-height: 150px;
        }
        @media only screen and (min-width: $iPhone5Height) and (orientation: landscape) {
          min-height: 65px;
        }

        li {
          font-size: 12px;
          line-height: 16px;
          font-family: $openSansRegular;
          color: #4C5560;
          position: relative;
          padding-left: 10px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          margin-bottom: 16px;

          &:before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #4C5560;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      &__buttons {
        padding: 20px 0 0 0;
        border-top: 1px solid #E8E9F4;
        background: $white-color;

        h3 {
          font-size: 16px;
          line-height: 22px;
          font-family: $openSansSemiBold;
          color: #494A58;
          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 10px;
          }
        }

        &-list {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding-top: 5px;
          padding-bottom: 0;

          li {
            width: calc(100%);
            margin: 0 3px;
          }

          button {
            font-size: 13px;
            line-height: 18px;
            font-family: $openSansSemiBold;
            color: $violet;
            transition: all 0.3s ease;
            border: 1px solid $violet;
            width: 100%;
            height: 36px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 767px) {
              font-size: 11px;
            }

            &:hover {
              color: $violet-hover;
              border: 1px solid $violet-hover;
            }

            i {
              font-size: 17px;
              margin-right: 6px;
              @media screen and (max-width: 767px) {
                font-size: 15px;
                margin-right: 6px;
              }
            }

            &.text {
              position: relative;

              &.active {
                &:before {
                  content: '';
                  position: absolute;
                  margin: auto;
                  top: calc(100% - 3px);
                  background: $white-color;
                  left: 0;
                  right: 0;
                  width: 7px;
                  height: 7px;
                  transform: rotate(45deg);
                  border-right: 1px solid $violet;
                  border-bottom: 1px solid $violet;
                }
              }
            }
          }
        }
      }
    }
  }

  .send-text {
    &[disabled] {
      opacity: .5;
      cursor: default;
    }
  }

  .message {
    color: #9D9EA8;
    text-align: center;
    margin-top: -30px;
    padding: 10px 0;
  }

  .phone-form {
    width: 100%;
    background-color: #F8F8FB;
    display: flex;
    padding: 20px 30px;
    left: 0;
    border-top: 1px solid #E8E9F4;
    height: 76px;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
      padding: 20px 10px;
    }

    &__wrapper {
      width: 100%;
      display: flex;
      align-items: flex-end;

      .input-wrapper {
        width: 100%;
        position: relative;

        input {
          width: 100%;
        }

        &__name {
          font-family: $openSansSemiBold;
          font-size: 12px;
          font-weight: normal;
          line-height: 1.5;
          color: #9d9ea8;
          @media screen and (max-width: 767px) {
            font-size: 10px;
          }
        }
      }

      span {
        color: $red-color;
        position: absolute;
        font-size: 10px;
        width: auto;
        margin-top: 3px;
        font-family: $openSansSemiBold;
        left: 0;
        top: calc(100% - 2px);
      }

      .button-wrapper {
        margin-left: 15px;
        flex-shrink: 0;

        button {
          height: 36px;
          width: 122px;
          @media screen and (max-width: 425px) {
            width: 80px;
          }
        }
      }
    }

    &__success {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 19px;
      font-family: $openSansBold;
      letter-spacing: 0.05em;
      color: $green-color;

      .icon {
        margin-right: 8px;
        width: 19px;
        height: 19px;
        border: 1px solid $green-color;
        position: relative;
        border-radius: 50px;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 4px;
          left: 6px;
          width: 5px;
          height: 8px;
          border: solid $green-color;
          border-width: 0 2px 2px 0;
          transition: initial !important;
          transform: rotate(41deg) !important;
        }
      }
    }

    &.double {
      height: unset;

      .phone-form__wrapper {
        flex-wrap: wrap;

        span {
          position: static;
        }
      }

      .input-wrapper {
        width: 48%;

        &__name {
          font-family: $openSansSemiBold;
          font-size: 12px;
          font-weight: normal;
          line-height: 1.5;
          color: #9d9ea8;
          @media screen and (max-width: 767px) {
            font-size: 10px;
          }
        }

        &__container {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          width: 100%;
        }
      }

      .button-wrapper {
        flex-grow: 1;
        margin-left: 0;

        button {
          width: 100%;
        }
      }
    }
  }

  .success {
    &__wrapper {
      padding: 0 0 0 3px;
    }

    &__header {
      width: 100%;
      height: 71px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;

      &:before {
        content: '';
        background: #D9ECCA;
        width: 120%;
        left: 50%;
        top: -25px;
        transform: translateX(-50%);
        height: 90px;
        border-radius: 100%;
        position: absolute;
      }

      .icon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $green-color;
        position: relative;
        z-index: 10;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 7px;
          left: 12px;
          width: 9px;
          height: 14px;
          border: solid $white-color;
          border-width: 0 2px 2px 0;
          transition: initial !important;
          transform: rotate(41deg) !important;
        }
      }
    }

    &__body {
      height: 90px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #4C5560;
      font-family: $openSansSemiBold;
    }
  }

  .error {
    color: $red-color;
    font-size: 12px;
    width: auto;
    margin-top: 3px;
  }
}
