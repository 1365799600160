@import "../utils/vars";
@import "../utils/mixins";

$firstDotBg: #7C81CF;
$firstDotBgOpacity: rgba($firstDotBg, 0.085);
$secondDotBg: #CF942D;
$secondDotBgOpacity: rgba($secondDotBg, 0.085);
$thirdDotBg: #2F9980;
$thirdDotOpacity: rgba($thirdDotBg, 0.085);
$fourDotBg: #ED536F;
$fourDotOpacity: rgba($fourDotBg, 0.085);
$fiveDotBg: #818AA1;
$fiveDotOpacity: rgba($fiveDotBg, 0.085);
$sixDotBg: #AD64F5;
$sixDotOpacity: rgba($sixDotBg, 0.085);
$sevenDotBg: #3295BF;
$sevenDotOpacity: rgba($sevenDotBg, 0.085);
$eightDotBg: #CF703A;
$eightDotOpacity: rgba($eightDotBg, 0.085);
$nineDotBg: #D959BD;
$nineDotOpacity: rgba($nineDotBg, 0.085);
$tenDotBg: #0E9C9C;
$tenDotOpacity: rgba($tenDotBg, 0.085);

ng-dropdown-panel {
  &.ng-select__calendar-page {
    border-radius: 4px !important;
    margin-top: 8px !important;
    padding: 0 !important;
    border: 1px solid #DFE3ED !important;
    border-top: 0 !important;
    background-color: $white-color !important;
    box-shadow: 0 3px 12px 1px rgba(48, 51, 64, 0.10) !important;

    .ng-dropdown-panel-items {
      .ng-option {
        padding: 12px 12px 12px 16px !important;
        transition: $transition !important;

        .ng-option-label {
          color: #2B303D !important;
          font-family: $openSansRegular !important;
          font-size: 14px !important;
          font-style: normal !important;
          line-height: 24px !important;
        }

        &.ng-option-selected.ng-option-marked,
        &.ng-option-selected {
          background-color: #DEE0FA !important;
        }

        &.ng-option-marked {
          background-color: lighten(#DEE0FA, 2%) !important;
        }
      }
    }
  }
}

// Calendars
app-calendar,
app-admission-calendar,
app-calendar-page {
  app-multiselect {
    .list {
      &__head {
        height: 36px !important;

        &::before {
          width: 20px;
          height: 20px;
          border: 0 !important;
          background: url("/assets/img/calendar-page/arrow-bottom.svg") no-repeat center;
          background-size: 20px 20px;
          top: 7px !important;
          right: 11px !important;
        }

        &.show {
          &::before {
            transform: rotate(180deg);
          }
        }
      }

      &__drop {
        position: absolute !important;
        top: calc(100% + 7px) !important;
        left: 0 !important;
        border-radius: 3px;
        background-color: $white-color;
        padding: 0 !important;
        box-shadow: 0 3px 11px 1px rgba(46, 55, 65, 0.10) !important;

        .form-group {
          padding: 0 !important;
          margin: 0;
        }
      }
    }
  }

  td {
    border: 1px solid #DFE3ED;
  }

  .ng-select-chips {
    .ng-dropdown-panel {
      position: absolute !important;
      top: calc(100% + 4px);
      border-radius: 3px;
      background-color: $white-color;
      box-shadow: 0 3px 11px 1px rgba(46, 55, 65, 0.10);
      border: 0;

      .ng-dropdown-panel-items {
        max-height: 195px;
        @media screen and (max-width: 767px) {
          max-height: 145px;
        }
        @media screen and (max-height: 650px) {
          max-height: 195px;
        }
        @media screen and (max-height: 500px) {
          max-height: 145px;
        }

        .ng-option-selected {
          .select-group {
            width: 100%;
            position: relative;

            input {
              position: absolute;
              z-index: 5;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
              display: block;
            }

            label {
              &:after {
                content: '';
                display: block;
                position: absolute;
                top: 12px;
                left: 6px;
                width: 4px;
                height: 7px;
                border: solid $violet;
                border-width: 0 2px 2px 0;
                transition: initial;
                transform: rotate(41deg);
              }

              &:before {
                border-color: $violet;
                background-color: #e7e8f8;
              }
            }

            &:hover {
              + label {
                &:before {
                  border-color: #ABACBB;
                }
              }
            }
          }

          label {
            position: relative;
            display: flex;
            width: 100%;
            align-items: center;
            cursor: pointer;
            padding: 8px 0 8px 26px;
            margin: 0;

            &::before {
              content: '';
              -webkit-appearance: none;
              position: absolute;
              top: 8px;
              left: 0;
              display: inline-block;
              cursor: pointer;
              width: 16px;
              height: 16px;
              min-width: 16px;
              min-height: 16px;
              border-radius: 3px;
              border: 1px solid #c8c8de;
            }
          }

          &.added {
            border-bottom: 2px dashed #DFE3ED;
            padding: 0 0 3px 0;
            margin: 0 0 5px 0;
          }
        }

        .ng-option {
          background-color: $white-color;
          color: #4C5560;
          font-family: $openSansRegular;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          display: flex;
          align-items: center;
          padding: 0 16px;

          b {
            font-family: $openSansSemiBold;
            font-weight: normal;
            display: inline-block;
          }

          .select-group {
            width: 100%;
            position: relative;

            input {
              position: absolute;
              z-index: 5;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
              display: block;

              &:hover {
                + label {
                  &:before {
                    border-color: #ABACBB;
                  }
                }
              }
            }

            label {
              position: relative;
              display: flex;
              width: 100%;
              align-items: center;
              cursor: pointer;
              padding: 8px 0 8px 26px;
              margin: 0;

              &::before {
                content: '';
                -webkit-appearance: none;
                position: absolute;
                top: 8px;
                left: 0;
                display: inline-block;
                cursor: pointer;
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                border-radius: 3px;
                border: 1px solid #c8c8de;
              }
            }

            &.added {
              border-bottom: 2px dashed #DFE3ED;
              padding: 0 0 3px 0;
              margin: 0 0 5px 0;
            }
          }

          .form-group {
            width: 100%;
            position: relative;

            input {
              position: absolute;
              z-index: 5;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
              display: block;

              &:checked {
                + label {
                  &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 12px;
                    left: 6px;
                    width: 4px;
                    height: 7px;
                    border: solid $violet;
                    border-width: 0 2px 2px 0;
                    transition: initial;
                    transform: rotate(41deg);
                  }

                  &:before {
                    border-color: $violet;
                    background-color: #e7e8f8;
                  }
                }
              }

              &:hover {
                + label {
                  &:before {
                    border-color: #ABACBB;
                  }
                }
              }
            }

            label {
              position: relative;
              display: flex;
              width: 100%;
              align-items: center;
              cursor: pointer;
              padding: 8px 0 8px 26px;
              margin: 0;

              &::before {
                content: '';
                -webkit-appearance: none;
                position: absolute;
                top: 8px;
                left: 0;
                display: inline-block;
                cursor: pointer;
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                border-radius: 3px;
                border: 1px solid #c8c8de;
              }
            }

            &.added {
              border-bottom: 2px dashed #DFE3ED;
              padding: 0 0 3px 0;
              margin: 0 0 5px 0;
            }
          }

          &.ng-option-disabled {
            color: #cccccc;
            font-size: 12px;
            padding: 10px;
          }

          &.ng-option-selected,
          &.ng-option-selected.ng-option-marked {
            background-color: $white-color;
          }
        }
      }
    }

    .ng-arrow-wrapper {
      position: relative !important;
      top: -1px;
    }

    .ng-clear-wrapper {
      .ng-clear-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ng-clear {
        position: relative;
        top: 1px;
      }
    }

    &.ng-select-multiple,
    &.ng-select-single {
      .ng-select-container {
        min-height: 34px;
        border-radius: 3px !important;
        border: 1px solid #CFD0DE !important;
        background-color: $white-color !important;
        overflow: auto;

        .ng-value-container {
          padding: 2px 0 0 7px;
          min-height: 34px;

          .ng-input {
            padding: 0;

            input {
              height: 34px;
            }
          }

          .ng-value {
            color: #4C5560;
            font-size: 12px;
            border-radius: 3px;
            margin: 0 4px 0 0;
            padding: 0 4px 0 2px;

            b {
              font-family: $openSansSemiBold;
              font-weight: normal;
            }

            .ng-value-icon {
              transition: $transition;

              &:hover {
                background-color: transparent;
                color: $red-color;
              }

              &.left {
                border-color: #C9CBF5;
              }
            }
          }
        }
      }
    }

    &.ng-select-single {
      .ng-select-container {
        min-height: 36px;

        .ng-input {
          padding-left: 7px !important;
          top: 0 !important;

        }
      }

      .ng-option {
        height: 34px;

        &:hover {
          color: $violet;
        }
      }
    }

    .ng-value {
      font-size: 12px;
      margin-top: 2px !important;
      margin-bottom: 2px !important;
      font-family: $openSansSemiBold;
    }
  }

  .mobile-header {
    display: none;
    padding: 20px 16px 12px 16px;
    @media screen and (max-width: 960px) {
      display: flex;
      align-items: center;
    }

    &__back {
      font-size: 13px;
      line-height: 18px;
      color: #9D9EA8;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      transition: $transition;

      i {
        position: relative;
        transform: rotate(180deg);
        margin-right: 10px;
        font-size: 10px;
        transition: $transition;
      }

      &:hover {
        color: $violet;

        i {
          color: $violet;
        }
      }
    }
  }

  .overlay-blur {
    z-index: 110;
  }

  .fc-scrollgrid {
    border: 0 !important;
  }

  .fc-header-toolbar {
    display: none !important;
  }

  .fc-col-header {
    background-color: #F2F5FA;

    .fc-col-header-cell {
      border: 0;

      .fc-scrollgrid-sync-inner {
        height: 24px;
        border: {
          width: 1px 0 1px 0;
          style: solid;
          color: #DFE3ED;
        }

        .fc-col-header-cell-cushion {
          color: #2B303D;
          font-family: $openSansRegular;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 0;
          position: relative;
          top: -1px;
        }
      }

      &:first-child {
        .fc-scrollgrid-sync-inner {
          border-left: 0;
        }
      }

      &:last-child {
        .fc-scrollgrid-sync-inner {
          border-right: 0;
        }
      }
    }
  }

  .fc {
    .fc-daygrid-day-top {
      flex-direction: row;
      opacity: 1;
      color: #2B303D;
      font-family: $openSansSemiBold;
      font-size: 12px;
      font-style: normal;
      line-height: 16px;
      padding: 0;
    }

    .fc-daygrid-day-number {
      padding: 8px 9px;
      width: 32px;
      height: 32px;
      transition: $transition;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #EBEEF5;
      }
    }

    .fc-day-other {
      .fc-daygrid-day-top {
        color: #959FB8;
        opacity: 1;
      }
    }

    .fc-daygrid-day {
      &.fc-day-today {
        background-color: $white-color;

        .fc-daygrid-day-top {
          .fc-daygrid-day-number {
            background-color: $violet;
            color: $white-color;
          }
        }
      }
    }

    .fc-daygrid-body-natural {
      .fc-daygrid-day-events {
        margin-bottom: 0;
      }
    }

    .fc-daygrid-day-bottom {
      padding: 0 0 0 6px;
      margin: 0;
    }

    .fc-daygrid-more-link {
      float: none;
      color: $violet;
      text-overflow: ellipsis;
      font-family: $openSansSemiBold;
      font-size: 12px;
      font-style: normal;
      line-height: 18px;
      transition: $transition;

      &:hover {
        background: transparent;
        color: $violet-hover;
      }
    }

    .fc-popover {
      border-radius: 3px;
      box-shadow: 0 2px 12px 0 rgba(46, 55, 65, 0.07);
      border: 0;
    }

    .fc-popover-header {
      background-color: #F2F5FA;
      padding: 6px;
    }

    .fc-popover-title {
      color: #2B303D;
      font-family: $openSansSemiBold;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
    }

    .fc-popover-close {
      color: $violet;
      transition: $transition;

      &:hover {
        color: $violet-hover;
      }
    }
  }

  .fc-popover-body {
    padding: 6px !important;
    max-height: 173px;
    overflow-y: auto;

    .fc-event {
      padding: 0 0 0 3px !important;
      transition: $transition;
      border: 1px solid transparent;
    }

    .fc-daygrid-event-dot {
      border: none;
      border-radius: 50%;
      height: 6px;
      width: 6px;
      margin: 0 5px 0 0;
      position: relative;
      top: 1px;
      flex-shrink: 0;
    }

    .fc-daygrid-event-harness {
      &:nth-child(5n + 1) {
        .fc-daygrid-event-dot {
          background-color: $firstDotBg;
        }

        .fc-event {
          &:hover {
            border-color: $firstDotBg;
            background-color: $firstDotBgOpacity;
          }
        }
      }

      &:nth-child(5n + 2) {
        .fc-daygrid-event-dot {
          background-color: $secondDotBg;
        }

        .fc-event {
          &:hover {
            border-color: $secondDotBg;
            background-color: $secondDotBgOpacity;
          }
        }
      }

      &:nth-child(5n + 3) {
        .fc-daygrid-event-dot {
          background-color: $thirdDotBg;
        }

        .fc-event {
          &:hover {
            border-color: $thirdDotBg;
            background-color: $thirdDotOpacity;
          }
        }
      }

      &:nth-child(5n + 4) {
        .fc-daygrid-event-dot {
          background-color: $fourDotBg;
        }

        .fc-event {
          &:hover {
            border-color: $fourDotBg;
            background-color: $fourDotOpacity;
          }
        }
      }

      &:nth-child(5n + 5) {
        .fc-daygrid-event-dot {
          background-color: $fiveDotBg;
        }

        .fc-event {
          &:hover {
            border-color: $fiveDotBg;
            background-color: $fiveDotOpacity;
          }
        }
      }

      &:nth-child(5n + 6) {
        .fc-daygrid-event-dot {
          background-color: $sixDotBg;
        }

        .fc-event {
          &:hover {
            border-color: $sixDotBg;
            background-color: $sixDotOpacity;
          }
        }
      }

      &:nth-child(5n + 7) {
        .fc-daygrid-event-dot {
          background-color: $sevenDotBg;
        }

        .fc-event {
          &:hover {
            border-color: $sevenDotBg;
            background-color: $sevenDotOpacity;
          }
        }
      }

      &:nth-child(5n + 8) {
        .fc-daygrid-event-dot {
          background-color: $eightDotBg;
        }

        .fc-event {
          &:hover {
            border-color: $eightDotBg;
            background-color: $eightDotOpacity;
          }
        }
      }

      &:nth-child(5n + 9) {
        .fc-daygrid-event-dot {
          background-color: $nineDotBg;
        }

        .fc-event {
          &:hover {
            border-color: $nineDotBg;
            background-color: $nineDotOpacity;
          }
        }
      }

      &:nth-child(5n + 10) {
        .fc-daygrid-event-dot {
          background-color: $tenDotBg;
        }

        .fc-event {
          &:hover {
            border-color: $tenDotBg;
            background-color: $tenDotOpacity;
          }
        }
      }

      .fc-event {
        &.first-user {
          .fc-daygrid-event-dot {
            background-color: $firstDotBg;
          }

          &:hover {
            border-color: $firstDotBg;
            background-color: $firstDotBgOpacity;
          }
        }

        &.second-user {
          .fc-daygrid-event-dot {
            background-color: $secondDotBg;
          }

          &:hover {
            border-color: $secondDotBg;
            background-color: $secondDotBgOpacity;
          }
        }

        &.third-user {
          .fc-daygrid-event-dot {
            background-color: $thirdDotBg;
          }

          &:hover {
            border-color: $thirdDotBg;
            background-color: $thirdDotOpacity;
          }
        }

        &.four-user {
          .fc-daygrid-event-dot {
            background-color: $fourDotBg;
          }

          &:hover {
            border-color: $fourDotBg;
            background-color: $fourDotOpacity;
          }
        }

        &.five-user {
          .fc-daygrid-event-dot {
            background-color: $fiveDotBg;
          }

          &:hover {
            border-color: $fiveDotBg;
            background-color: $fiveDotOpacity;
          }
        }

        &.six-user {
          .fc-daygrid-event-dot {
            background-color: $sixDotBg;
          }

          &:hover {
            border-color: $sixDotBg;
            background-color: $sixDotOpacity;
          }
        }

        &.seven-user {
          .fc-daygrid-event-dot {
            background-color: $sevenDotBg;
          }

          &:hover {
            border-color: $sevenDotBg;
            background-color: $sevenDotOpacity;
          }
        }

        &.eight-user {
          .fc-daygrid-event-dot {
            background-color: $eightDotBg;
          }

          &:hover {
            border-color: $eightDotBg;
            background-color: $eightDotOpacity;
          }
        }

        &.nine-user {
          .fc-daygrid-event-dot {
            background-color: $nineDotBg;
          }

          &:hover {
            border-color: $nineDotBg;
            background-color: $nineDotOpacity;
          }
        }

        &.ten-user {
          .fc-daygrid-event-dot {
            background-color: $tenDotBg;
          }

          &:hover {
            border-color: $tenDotBg;
            background-color: $tenDotOpacity;
          }
        }
      }
    }

    .fc-event-time {
      color: #2B303D;
      font-family: $openSansRegular;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      margin-right: 2px;
    }

    .fc-event-title {
      overflow: hidden;
      color: #2B303D;
      text-overflow: ellipsis;
      font-family: $openSansSemiBold;
      font-size: 12px;
      font-style: normal;
      line-height: 16px;
    }
  }

  .fc-daygrid-day-frame {
    padding: 4px;
  }

  .fc-daygrid-body {
    .fc-day-sat,
    .fc-day-sun {
      background-color: #F7F9FC !important;
    }
  }

  .fc-dayGridMonth-view {
    td {
      background-color: $white-color;
    }

    .fc-daygrid-day-frame {
      min-height: 100px !important;
    }

    .fc-daygrid-event-dot {
      border: none;
      border-radius: 50%;
      height: 6px;
      width: 6px;
      margin: 0 5px 0 0;
      position: relative;
      top: 1px;
      flex-shrink: 0;
    }

    .fc-direction-ltr {
      .fc-daygrid-event {
        .fc-event-time {
          color: #2B303D;
          font-family: $openSansRegular;
          font-size: 12px;
          font-style: normal;
          line-height: normal;
          margin-right: 2px;
        }
      }
    }

    .fc-daygrid-dot-event {
      .fc-event-title {
        overflow: hidden;
        color: #2B303D;
        text-overflow: ellipsis;
        font-family: $openSansSemiBold;
        font-size: 12px;
        font-style: normal;
        line-height: 16px;
      }
    }

    .fc-daygrid-day-events {
      .fc-daygrid-event-harness {
        &:nth-child(5n + 1) {
          .fc-daygrid-event-dot {
            background-color: $firstDotBg;
          }

          .fc-event {
            &:hover {
              border-color: $firstDotBg;
              background-color: $firstDotBgOpacity;
            }
          }
        }

        &:nth-child(5n + 2) {
          .fc-daygrid-event-dot {
            background-color: $secondDotBg;
          }

          .fc-event {
            &:hover {
              border-color: $secondDotBg;
              background-color: $secondDotBgOpacity;
            }
          }
        }

        &:nth-child(5n + 3) {
          .fc-daygrid-event-dot {
            background-color: $thirdDotBg;
          }

          .fc-event {
            &:hover {
              border-color: $thirdDotBg;
              background-color: $thirdDotOpacity;
            }
          }
        }

        &:nth-child(5n + 4) {
          .fc-daygrid-event-dot {
            background-color: $fourDotBg;
          }

          .fc-event {
            &:hover {
              border-color: $fourDotBg;
              background-color: $fourDotOpacity;
            }
          }
        }

        &:nth-child(5n + 5) {
          .fc-daygrid-event-dot {
            background-color: $fiveDotBg;
          }

          .fc-event {
            &:hover {
              border-color: $fiveDotBg;
              background-color: $fiveDotOpacity;
            }
          }
        }

        &:nth-child(5n + 6) {
          .fc-daygrid-event-dot {
            background-color: $sixDotBg;
          }

          .fc-event {
            &:hover {
              border-color: $sixDotBg;
              background-color: $sixDotOpacity;
            }
          }
        }

        &:nth-child(5n + 7) {
          .fc-daygrid-event-dot {
            background-color: $sevenDotBg;
          }

          .fc-event {
            &:hover {
              border-color: $sevenDotBg;
              background-color: $sevenDotOpacity;
            }
          }
        }

        &:nth-child(5n + 8) {
          .fc-daygrid-event-dot {
            background-color: $eightDotBg;
          }

          .fc-event {
            &:hover {
              border-color: $eightDotBg;
              background-color: $eightDotOpacity;
            }
          }
        }

        &:nth-child(5n + 9) {
          .fc-daygrid-event-dot {
            background-color: $nineDotBg;
          }

          .fc-event {
            &:hover {
              border-color: $nineDotBg;
              background-color: $nineDotOpacity;
            }
          }
        }

        &:nth-child(5n + 10) {
          .fc-daygrid-event-dot {
            background-color: $tenDotBg;
          }

          .fc-event {
            &:hover {
              border-color: $tenDotBg;
              background-color: $tenDotOpacity;
            }
          }
        }

        .fc-event {
          &.first-user {
            .fc-daygrid-event-dot {
              background-color: $firstDotBg;
            }

            &:hover {
              border-color: $firstDotBg;
              background-color: $firstDotBgOpacity;
            }
          }

          &.second-user {
            .fc-daygrid-event-dot {
              background-color: $secondDotBg;
            }

            &:hover {
              border-color: $secondDotBg;
              background-color: $secondDotBgOpacity;
            }
          }

          &.third-user {
            .fc-daygrid-event-dot {
              background-color: $thirdDotBg;
            }

            &:hover {
              border-color: $thirdDotBg;
              background-color: $thirdDotOpacity;
            }
          }

          &.four-user {
            .fc-daygrid-event-dot {
              background-color: $fourDotBg;
            }

            &:hover {
              border-color: $fourDotBg;
              background-color: $fourDotOpacity;
            }
          }

          &.five-user {
            .fc-daygrid-event-dot {
              background-color: $fiveDotBg;
            }

            &:hover {
              border-color: $fiveDotBg;
              background-color: $fiveDotOpacity;
            }
          }

          &.six-user {
            .fc-daygrid-event-dot {
              background-color: $sixDotBg;
            }

            &:hover {
              border-color: $sixDotBg;
              background-color: $sixDotOpacity;
            }
          }

          &.seven-user {
            .fc-daygrid-event-dot {
              background-color: $sevenDotBg;
            }

            &:hover {
              border-color: $sevenDotBg;
              background-color: $sevenDotOpacity;
            }
          }

          &.eight-user {
            .fc-daygrid-event-dot {
              background-color: $eightDotBg;
            }

            &:hover {
              border-color: $eightDotBg;
              background-color: $eightDotOpacity;
            }
          }

          &.nine-user {
            .fc-daygrid-event-dot {
              background-color: $nineDotBg;
            }

            &:hover {
              border-color: $nineDotBg;
              background-color: $nineDotOpacity;
            }
          }

          &.ten-user {
            .fc-daygrid-event-dot {
              background-color: $tenDotBg;
            }

            &:hover {
              border-color: $tenDotBg;
              background-color: $tenDotOpacity;
            }
          }
        }
      }
    }

    .fc-event {
      transition: $transition;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid transparent;
      @media screen and (max-width: 767px) {
        justify-content: center;
        padding: 0 !important;
      }

      .fc-daygrid-event-dot {
        @media screen and (max-width: 767px) {
          margin: 0;
          width: 10px;
          height: 10px;
        }
      }

      .fc-event-time,
      .fc-event-title {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
  }

  .fc-timeGridWeek-view,
  .fc-timeGridDay-view {
    .fc-day {
      background-color: $white-color;
    }

    .fc-day-today {
      .fc-timegrid-col-frame {
        background-color: $white-color;
      }
    }

    .fc-timegrid-cols {
      .fc-day-sat,
      .fc-day-sun {
        background-color: #F7F9FC;
      }
    }

    .fc-timegrid-slot-minor {
      border-top-style: none;
    }

    .fc-col-header {
      border-top: 1px solid #DFE3ED;
      border-bottom: 1px solid #DFE3ED;

      th {
        background-color: #F2F5FA;
        border: {
          width: 0 1px 0 0;
          style: solid;
          color: #DFE3ED;
        };

        .fc-scrollgrid-sync-inner {
          padding: 8px 0;
          height: 82px;
          display: flex;
          align-items: center;
          justify-content: center;

          .header-week-date {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
              padding: 0;
              margin: 0;
              display: inline-flex;
              align-items: center;
              justify-content: center;

              &:first-child {
                color: #2B303D;
                text-align: center;
                font-family: $openSansRegular;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-bottom: 4px;
              }

              &:last-child {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                color: #2B303D;
                font-family: $openSansSemiBold;
                font-size: 22px;
                font-style: normal;
                line-height: 1;
                @media screen and (max-width: 600px) {
                  font-size: 14px;
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }
      }

      .fc-col-header-cell {
        .fc-scrollgrid-sync-inner {
          border: 0;
        }
      }
    }

    .fc-col-header-cell {
      .fc-col-header-cell-cushion {
        display: none;
      }

      &.fc-day-today {
        .header-week-date {
          p {
            &:last-child {
              background-color: $violet;
              color: $white-color !important;
            }
          }
        }
      }
    }

    .fc-timegrid-axis-frame {
      justify-content: flex-end;
      background-color: $white-color;

      .fc-timegrid-axis-cushion {
        color: #2B303D;
        font-family: $openSansSemiBold;
        font-size: 12px;
        font-style: normal;
        line-height: 16px;
        padding: 0 7px 0 0;

        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    .fc-timegrid-axis {
      background-color: $white-color;
    }

    .fc-scrollgrid-section {
      height: 0;
    }

    .fc-timegrid-divider {
      display: none;
      border: 0;
    }

    .fc-timegrid-slot-label-cushion {
      color: #2B303D;
      text-align: right;
      font-family: $openSansRegular;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      position: relative;
      top: -1px;
      padding: 0 7px 0 15px;
    }
  }

  .fc-timegrid-col-events {
    .fc-timegrid-event-harness {
      @media screen and (max-width: 600px) {
        padding: 5px;
      }

      .fc-v-event {
        box-shadow: none;
        padding: 8px;
        border-radius: 3px;
        transition: $transition;
        @media screen and (max-width: 600px) {
          padding: 0;
        }

        .fc-event-main {
          @media screen and (max-width: 600px) {
            display: none;
          }
        }
      }

      &:nth-child(5n + 1) {
        .fc-v-event {
          border: 1px solid $firstDotBg;
          background-color: $firstDotBgOpacity;

          &:hover {
            background-color: rgba($firstDotBg, 0.35);
          }
        }
      }

      &:nth-child(5n + 2) {
        .fc-v-event {
          border: 1px solid $secondDotBg;
          background-color: $secondDotBgOpacity;

          &:hover {
            background-color: rgba($secondDotBg, 0.35);
          }
        }
      }

      &:nth-child(5n + 3) {
        .fc-v-event {
          border: 1px solid $thirdDotBg;
          background-color: $thirdDotOpacity;

          &:hover {
            background-color: rgba($thirdDotBg, 0.35);
          }
        }
      }

      &:nth-child(5n + 4) {
        .fc-v-event {
          border: 1px solid $fourDotBg;
          background-color: $fourDotOpacity;

          &:hover {
            background-color: rgba($fourDotBg, 0.35);
          }
        }
      }

      &:nth-child(5n + 5) {
        .fc-v-event {
          border: 1px solid $fiveDotBg;
          background-color: $fiveDotOpacity;

          &:hover {
            background-color: rgba($fiveDotBg, 0.35);
          }
        }
      }

      &:nth-child(5n + 6) {
        .fc-v-event {
          border: 1px solid $sixDotBg;
          background-color: $sixDotOpacity;

          &:hover {
            background-color: rgba($sixDotBg, 0.35);
          }
        }
      }

      &:nth-child(5n + 7) {
        .fc-v-event {
          border: 1px solid $sevenDotBg;
          background-color: $sevenDotOpacity;

          &:hover {
            background-color: rgba($sevenDotBg, 0.35);
          }
        }
      }

      &:nth-child(5n + 8) {
        .fc-v-event {
          border: 1px solid $eightDotBg;
          background-color: $eightDotOpacity;

          &:hover {
            background-color: rgba($eightDotBg, 0.35);
          }
        }
      }

      &:nth-child(5n + 9) {
        .fc-v-event {
          border: 1px solid $nineDotBg;
          background-color: $nineDotOpacity;

          &:hover {
            background-color: rgba($nineDotBg, 0.35);
          }
        }
      }

      &:nth-child(5n + 10) {
        .fc-v-event {
          border: 1px solid $tenDotBg;
          background-color: $tenDotOpacity;

          &:hover {
            background-color: rgba($tenDotBg, 0.35);
          }
        }
      }

      .fc-v-event {
        &.first-user {
          border: 1px solid $firstDotBg;
          background-color: $firstDotBgOpacity;

          &:hover {
            background-color: rgba($firstDotBg, 0.35);
          }
        }

        &.second-user {
          border: 1px solid $secondDotBg;
          background-color: $secondDotBgOpacity;

          &:hover {
            background-color: rgba($secondDotBg, 0.35);
          }
        }

        &.third-user {
          border: 1px solid $thirdDotBg;
          background-color: $thirdDotOpacity;

          &:hover {
            background-color: rgba($thirdDotBg, 0.35);
          }
        }

        &.third-user {
          border: 1px solid $thirdDotBg;
          background-color: $thirdDotOpacity;

          &:hover {
            background-color: rgba($thirdDotBg, 0.35);
          }
        }

        &.four-user {
          border: 1px solid $fourDotBg;
          background-color: $fourDotOpacity;

          &:hover {
            background-color: rgba($fourDotBg, 0.35);
          }
        }

        &.five-user {
          border: 1px solid $fiveDotBg;
          background-color: $fiveDotOpacity;

          &:hover {
            background-color: rgba($fiveDotBg, 0.35);
          }
        }

        &.six-user {
          border: 1px solid $sixDotBg;
          background-color: $sixDotOpacity;

          &:hover {
            background-color: rgba($sixDotBg, 0.35);
          }
        }

        &.seven-user {
          border: 1px solid $sevenDotBg;
          background-color: $sevenDotOpacity;

          &:hover {
            background-color: rgba($sevenDotBg, 0.35);
          }
        }

        &.eight-user {
          border: 1px solid $eightDotBg;
          background-color: $eightDotOpacity;

          &:hover {
            background-color: rgba($eightDotBg, 0.35);
          }
        }

        &.nine-user {
          border: 1px solid $nineDotBg;
          background-color: $nineDotOpacity;

          &:hover {
            background-color: rgba($nineDotBg, 0.35);
          }
        }

        &.ten-user {
          border: 1px solid $tenDotBg;
          background-color: $tenDotOpacity;

          &:hover {
            background-color: rgba($tenDotBg, 0.35);
          }
        }
      }
    }
  }

  .fc-timegrid-event {
    .fc-event-main {
      padding: 0;
      color: #2B303D;

      .fc-event-time {
        font-family: $openSansRegular;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        display: inline-block;
        padding-right: 2px;
        text-overflow: ellipsis;
      }

      .fc-event-title {
        font-family: $openSansSemiBold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .fc-timeGridDay-view {
    .fc-col-header {
      th {
        .fc-scrollgrid-sync-inner {
          padding-left: 18px;
          justify-content: flex-start;
        }
      }
    }

    .fc-timegrid-col-events {
      .fc-timegrid-event-harness {
        .fc-v-event {
          @media screen and (max-width: 600px) {
            border-radius: 4px;
            width: auto;
            height: auto;
            position: absolute;
            padding: 4px;
          }

          .fc-event-main {
            @media screen and (max-width: 600px) {
              display: block;
            }

            .fc-event-title-container {
              @media screen and (max-width: 600px) {
                font-size: 10px;
              }
            }
          }
        }

        &:nth-child(5n + 1) {
          .fc-v-event {
            border: 1px solid $firstDotBg;
            background-color: $firstDotBgOpacity;

            &:hover {
              background-color: rgba($firstDotBg, 0.35);
            }
          }
        }

        &:nth-child(5n + 2) {
          .fc-v-event {
            border: 1px solid $secondDotBg;
            background-color: $secondDotBgOpacity;

            &:hover {
              background-color: rgba($secondDotBg, 0.35);
            }
          }
        }

        &:nth-child(5n + 3) {
          .fc-v-event {
            border: 1px solid $thirdDotBg;
            background-color: $thirdDotOpacity;

            &:hover {
              background-color: rgba($thirdDotBg, 0.35);
            }
          }
        }

        &:nth-child(5n + 4) {
          .fc-v-event {
            border: 1px solid $fourDotBg;
            background-color: $fourDotOpacity;

            &:hover {
              background-color: rgba($fourDotBg, 0.35);
            }
          }
        }

        &:nth-child(5n + 5) {
          .fc-v-event {
            border: 1px solid $fiveDotBg;
            background-color: $fiveDotOpacity;

            &:hover {
              background-color: rgba($fiveDotBg, 0.35);
            }
          }
        }

        &:nth-child(5n + 6) {
          .fc-v-event {
            border: 1px solid $sixDotBg;
            background-color: $sixDotOpacity;

            &:hover {
              background-color: rgba($sixDotBg, 0.35);
            }
          }
        }

        &:nth-child(5n + 7) {
          .fc-v-event {
            border: 1px solid $sevenDotBg;
            background-color: $sevenDotOpacity;

            &:hover {
              background-color: rgba($sevenDotBg, 0.35);
            }
          }
        }

        &:nth-child(5n + 8) {
          .fc-v-event {
            border: 1px solid $eightDotBg;
            background-color: $eightDotOpacity;

            &:hover {
              background-color: rgba($eightDotBg, 0.35);
            }
          }
        }

        &:nth-child(5n + 9) {
          .fc-v-event {
            border: 1px solid $nineDotBg;
            background-color: $nineDotOpacity;

            &:hover {
              background-color: rgba($nineDotBg, 0.35);
            }
          }
        }

        &:nth-child(5n + 10) {
          .fc-v-event {
            border: 1px solid $tenDotBg;
            background-color: $tenDotOpacity;

            &:hover {
              background-color: rgba($tenDotBg, 0.35);
            }
          }
        }

        .fc-v-event {
          &.first-user {
            border: 1px solid $firstDotBg;
            background-color: $firstDotBgOpacity;

            &:hover {
              background-color: rgba($firstDotBg, 0.35);
            }
          }

          &.second-user {
            border: 1px solid $secondDotBg;
            background-color: $secondDotBgOpacity;

            &:hover {
              background-color: rgba($secondDotBg, 0.35);
            }
          }

          &.third-user {
            border: 1px solid $thirdDotBg;
            background-color: $thirdDotOpacity;

            &:hover {
              background-color: rgba($thirdDotBg, 0.35);
            }
          }

          &.four-user {
            border: 1px solid $fourDotBg;
            background-color: $fourDotOpacity;

            &:hover {
              background-color: rgba($fourDotBg, 0.35);
            }
          }

          &.five-user {
            border: 1px solid $fiveDotBg;
            background-color: $fiveDotOpacity;

            &:hover {
              background-color: rgba($fiveDotBg, 0.35);
            }
          }

          &.six-user {
            border: 1px solid $sixDotBg;
            background-color: $sixDotOpacity;

            &:hover {
              background-color: rgba($sixDotBg, 0.35);
            }
          }

          &.seven-user {
            border: 1px solid $sevenDotBg;
            background-color: $sevenDotOpacity;

            &:hover {
              background-color: rgba($sevenDotBg, 0.35);
            }
          }

          &.eight-user {
            border: 1px solid $eightDotBg;
            background-color: $eightDotOpacity;

            &:hover {
              background-color: rgba($eightDotBg, 0.35);
            }
          }

          &.nine-user {
            border: 1px solid $nineDotBg;
            background-color: $nineDotOpacity;

            &:hover {
              background-color: rgba($nineDotBg, 0.35);
            }
          }

          &.ten-user {
            border: 1px solid $tenDotBg;
            background-color: $tenDotOpacity;

            &:hover {
              background-color: rgba($tenDotBg, 0.35);
            }
          }
        }
      }
    }

    .fc-timegrid-event {
      .fc-event-main {
        .fc-event-time {
          @media screen and (max-width: 600px) {
            font-size: 10px;
          }
        }
      }
    }
  }

  .fc-direction-ltr,
  .fc-direction-rtl {
    .fc-daygrid-event {
      &.fc-event-end,
      &.fc-event-start {
        margin: 0;
        min-height: 20px;
        align-items: center;
        padding: 0 0 0 10px;
        font-size: 12px;
      }
    }
  }

  .fc-daygrid-dot-event {
    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }
  }

  .fc-v-event {
    .fc-event-main {
      height: auto;
      padding-bottom: 5px;
    }
  }

  .flatpickr-calendar {
    right: initial !important;
  }

  .blur {
    opacity: 0.3;
    pointer-events: none;
  }

  .google-calendar {
    position: absolute;
    height: 40px;
    min-width: 186px;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-family: $openSansSemiBold;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    color: $white-color;
    border-radius: 3px;
    background-color: $violet;
    transition: $transition;

    &:hover {
      background-color: $violet-hover;
    }

    i {
      font-size: 18px;
      display: inline-block;
      margin-right: 9px;
    }

    &.hide {
      display: none;
    }
  }

  .ng-select {
    &:not(.search-select, .ng-select-chips) {
      min-width: 100px;

      .ng-select-container {
        border-radius: 4px;
        border: 1px solid #C8CEDE !important;
        background-color: #F7F9FC;
        cursor: pointer;
        transition: $transition;

        .ng-value-container {
          padding: 0 0 0 11px;

          .ng-placeholder,
          .ng-value {
            color: #2B303D;
            font-family: $openSansRegular;
            font-size: 14px;
            font-style: normal;
            line-height: 24px;
          }
        }
      }

      .ng-arrow-wrapper {
        padding: 0;
        width: 20px;
        height: 20px;
        margin: 0 11px;
        display: inline-block !important;
        position: absolute;
        right: 0;

        .ng-arrow {
          border: 0;
          width: 20px;
          height: 20px;
          background: url("/assets/img/calendar-page/arrow-bottom.svg") no-repeat center;
          background-size: 20px 20px;
        }
      }

      .ng-clear-wrapper {
        display: none;
      }

      .ng-value {
        top: inherit !important;
        left: inherit !important;
      }

      &.ng-select-single {
        .ng-select-container {
          height: 30px;
          min-height: 30px;
        }
      }

      &.ng-select-opened {
        > .ng-select-container {
          background-color: $white-color;
          border: 1px solid $violet !important;

          .ng-arrow {
            top: 1px;
            border: 0;
            transform: rotate(180deg);
          }
        }

        &.ng-select-bottom {
          > .ng-select-container {
            border-radius: 4px;
          }
        }
      }
    }

    &.error {
      .ng-select-container {
        border: 1px solid $red-color !important;
        border-radius: 4px;
      }
    }

  }

  .search-fields {
    .ng-dropdown-panel {
      position: absolute !important;
      background-color: $white-color !important;
    }

    .ng-select {
      &.form-select {
        min-width: 284px;

        input {
          height: 32px
        }

        .ng-dropdown-panel {
          background: $white-color;
          box-shadow: 0 2px 12px rgba(73, 74, 88, 0.07);
          border-radius: 3px;
          border: none;
          padding: 10px 0;

          .ng-dropdown-panel-items {
            .ng-option {
              font-size: 12px;
              padding: 4px 10px;

              &:hover {
                background: transparent;
                color: $violet;
              }

              &.ng-option-marked {
                background: transparent;
              }
            }
          }
        }

        .ng-select-container {
          min-width: 186px;
          margin-right: 20px;
          background: transparent;
          height: 32px;
          border: 1px solid #CFD0DE;
          border-radius: 3px;
          background: url(/assets/img/icons/search.svg) no-repeat 10px 8px;
          padding-left: 29px;
          color: #b7bbbf;
          font-family: "OpenSans-SemiBold", sans-serif;
          font-size: 13px;
          font-weight: normal;


          .ng-value-container .ng-value,
          .ng-value-container {
            padding-left: 0;
          }

          .ng-value-container {
            .ng-input {
              padding-left: 29px !important;
              top: 0;
            }

            .ng-placeholder {
              color: #b7bbbf;
              font-family: $openSansRegular;
              font-size: 12px;
              font-style: normal;
              line-height: 24px;
            }
          }
        }

        .ng-select {
          .ng-select-container {
            .ng-value-container {
              .ng-input > input {
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
        }

        &.err {
          .ng-select-container {
            border-color: $red-color !important;
          }
        }

        &.clear {
          .ng-clear-wrapper {
            display: block;
            position: absolute;
            right: 10px;

            &:hover {
              .ng-clear {
                color: $violet
              }
            }
          }
        }
      }

      &.ng-select-disabled {
        > .ng-select-container {
          background-color: #f1f1f1;
          border-color: #dddee8 !important;
        }
      }

      .ng-arrow-wrapper {
        display: none !important;
      }
    }

    .pointer-events-none {
      .ng-select {
        &.form-select {
          .ng-clear-wrapper {
            display: none;
          }
        }
      }
    }
  }

  .p-calendar {
    border-radius: 3px;
    box-shadow: 0 2px 12px 0 rgba(46, 55, 65, 0.07);
    position: relative;

    &__loader {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9999999999;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: $white-color;

      img {
        width: 100%;
        max-width: 120px;
        height: auto;
      }
    }

    &__header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 12px 16px;
      background-color: $white-color;
      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }

    &__cell {
      @media screen and (max-width: 767px) {
        width: 100%;
      }

      &:first-child {
        display: flex;
        align-items: center;
        @media screen and (max-width: 767px) {
          justify-content: flex-start;
          flex-wrap: wrap;
          padding-bottom: 10px;
        }
        @media screen and (max-width: 600px) {
          flex-wrap: wrap;
          padding-bottom: 0;
        }

        .calendar {
          @media screen and (max-width: 767px) {
            margin-bottom: 10px;
          }
          @media screen and (max-width: 600px) {
            width: 100%;
          }
        }
      }

      &:last-child {
        @media screen and (max-width: 767px) {
          display: flex;
          justify-content: flex-start;
        }

        ng-select {
          @media screen and (max-width: 767px) {
            width: 100%;
            max-width: 200px;
          }
          @media screen and (max-width: 600px) {
            max-width: 100%;
          }
        }
      }

      .calendar {
        &.showCalendar {
          .p-calendar {
            &__date-label {
              > input {
                background: url("/assets/img/calendar-page/open-calendar-arrow.svg") no-repeat calc(100% - 8px) 8px;
                background-size: 32px 32px;
              }
            }
          }
        }
      }
    }

    &__date-label {
      display: inline-block;
      margin-right: 20px;
      width: 184px;
      max-width: 184px;
      position: relative;
      @media screen and (max-width: 600px) {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
      }

      > input {
        width: 100%;
        height: 48px;
        border: 0;
        color: #2B303D;
        font-family: $openSansSemiBold;
        font-size: 18px;
        font-style: normal;
        line-height: 120%;
        padding: 0;
        border-radius: 0;
        background: url("/assets/img/calendar-page/arrow-bottom.svg") no-repeat calc(100% - 14px) 14px;
        background-size: 20px 20px;
        transition: initial;

        &:focus,
        &:active {
          box-shadow: none;
        }
      }

      .flatpickr-calendar.flatpickr-monthSelect-theme-light {
        width: 442px !important;
        max-width: 442px;
        padding: 24px 24px 8px 24px;
        border-radius: 3px;
        border: 1px solid #DFE3ED;
        background-color: $white-color;
        box-shadow: 0 4px 17px 1px rgba(48, 51, 64, 0.17);
        @media screen and (max-width: 1420px) {
          width: 442px !important;
          max-width: 442px !important;
        }
        @media screen and (max-width: 500px) {
          width: 300px !important;
          max-width: 300px !important;
          padding: 24px 10px 8px 10px;
        }
        @media screen and (max-width: 374px) {
          width: 280px !important;
          max-width: 280px !important;
          padding: 24px 5px 8px 5px;
        }

        .flatpickr-months {
          width: 100%;
          margin: 0;
          height: 40px !important;

          .flatpickr-month {
            height: 40px !important;
          }

          .flatpickr-current-month {
            height: 40px !important;

            .numInputWrapper {
              position: relative;
              left: 4px;
              width: 55px !important;
            }

            input {
              &.cur-year {
                color: #2B303D !important;
                font-family: $openSansSemiBold;
                font-size: 16px;
                font-style: normal;
                line-height: 24px;
              }
            }
          }

          .flatpickr-prev-month,
          .flatpickr-next-month {
            width: 40px;
            height: 40px;
            display: inline-block;
            position: relative;
            transition: $transition;
            cursor: pointer;

            &::after {
              display: none;
            }

            &:hover {
              opacity: $hoverOpacity;
            }
          }

          .flatpickr-prev-month {
            background: url("/assets/img/calendar-page/arrow-prev.svg") no-repeat center;
            background-size: 24px 24px;
          }

          .flatpickr-next-month {
            background: url("/assets/img/calendar-page/arrow-next.svg") no-repeat center;
            background-size: 24px 24px;
          }
        }

        .flatpickr-innerContainer {
          padding: 24px 0 0 0;
          margin-top: 8px;
          border-top: 1px solid #DFE3ED;
        }

        .flatpickr-monthSelect-months {

          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .flatpickr-monthSelect-month {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            margin: 0 0 16px 0;
            width: calc((100% / 3) - 8px);
            border-radius: 8px;
            border: 1px solid #DFE3ED;
            background-color: #F7F9FC;
            color: #4B5263;
            font-family: $openSansRegular;
            font-size: 14px;
            font-style: normal;
            line-height: 1;
            cursor: pointer;
            transition: $transition;

            &:hover {
              border-color: #969BE0;
              background-color: #F7F8FF;
              color: #6469B7;
            }

            &.selected {
              border-color: #6469B7;
              background-color: #EBECFC;
              color: #6469B7;
              font-family: $openSansSemiBold;
            }
          }
        }
      }
    }

    &__today {
      min-height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 4px 10px;
      margin-right: 36px;
      border-radius: 4px;
      border: 1px solid #C8CEDE;
      background-color: #F7F9FC;
      color: #2B303D;
      text-overflow: ellipsis;
      font-family: $openSansRegular;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      transition: $transition;
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
        margin-right: 0;
        order: 2;
      }

      &:hover {
        border-color: darken(#C8CEDE, 5%);
        background-color: darken(#F7F9FC, 2%);
      }
    }

    &__name {
      color: #677085;
      font-family: $openSansSemiBold;
      font-size: 14px;
      font-style: normal;
      line-height: 120%;
      text-transform: uppercase;
      padding-right: 10px;
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
      @media screen and (max-width: 600px) {
        margin-bottom: 15px;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      padding-right: 11px;
      position: relative;
      @media screen and (max-width: 767px) {
        top: -4px;
      }
      @media screen and (max-width: 600px) {
        top: initial;
      }

      &--prev,
      &--next {
        width: 32px;
        height: 32px;
        transition: $transition;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          opacity: $hoverOpacity;
        }
      }
    }
  }

  .search-by {
    position: relative;
    width: 100%;
    max-width: 280px;
    margin-right: 16px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      margin: 0 0 20px 0;
    }
    @media screen and (max-width: 600px) {
      max-width: 100%;
    }

    input {
      &[disabled] {
        background-color: transparent;
      }
    }

    .search-select {
      width: 100%;
      max-width: 100%;
      margin: 0;
    }

    &__drop {
      position: absolute;
      z-index: 999;
      top: calc(100% + 4px);
      left: 0;
      right: 0;
      width: 100%;
      max-height: 368px;
      overflow-y: auto;
      padding: 16px;
      border-radius: 3px;
      background-color: $white-color;
      box-shadow: 0 3px 11px 1px rgba(46, 55, 65, 0.10);
      scrollbar-width: thin !important;
      scrollbar-color: rgba(76, 85, 96, 0.4) rgba(76, 85, 96, 0) !important;
      @include scrollbar($scrollBarColor, transparent);
      @media screen and (max-height: 550px) {
        max-height: 250px;
      }
      @media screen and (max-height: 450px) {
        max-height: 150px;
      }
    }
  }

  .form-group {
    padding: 0 0 16px 0;

    label {
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      cursor: pointer;
      padding: 0 0 0 25px;
      color: #4C5560;
      font-family: $openSansRegular;
      font-size: 12px;
      font-style: normal;
      line-height: 16px;

      &::before {
        content: '';
        -webkit-appearance: none;
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        cursor: pointer;
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        border-radius: 3px;
        border: 1px solid #c8c8de;
      }

      &::after {
        content: '';
        display: none;
        position: absolute;
        top: 4px;
        left: 6px;
        width: 4px;
        height: 7px;
        border: solid $violet;
        border-width: 0 2px 2px 0;
        transition: initial;
        transform: rotate(41deg);
      }

      &:hover {
        &::before {
          border-color: #ABACBB;
        }
      }
    }

    input {
      display: none;

      &.checked {
        + label {
          &::after {
            display: block;
          }

          &::before {
            background-color: #E7E8F8;
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .search-chips {
    display: flex;
    align-items: center;
    overflow-y: auto;
    max-width: 100%;
    min-height: 32px;
    scrollbar-width: none !important;
    @include scrollbar(transparent, transparent);

    &__item {
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      height: 32px;
      border-radius: 3px;
      border: 1px solid transparent;
      padding: 3px 8px;
      margin-right: 8px;
      color: #1D222E;
      font-family: $openSansRegular;
      font-size: 14px;
      font-style: normal;
      line-height: 1;
      @media screen and (max-width: 767px) {
        margin-bottom: 8px;
      }

      &:nth-child(1) {
        border-color: $firstDotBg;
        background-color: $firstDotBgOpacity;
      }

      &:nth-child(2) {
        border-color: $secondDotBg;
        background-color: $secondDotBgOpacity;
      }

      &:nth-child(3) {
        border-color: $thirdDotBg;
        background-color: $thirdDotOpacity;
      }

      &:nth-child(4) {
        border-color: $fourDotBg;
        background-color: $fourDotOpacity;
      }

      &:nth-child(5) {
        border-color: $fiveDotBg;
        background-color: $fiveDotOpacity;
      }

      &:nth-child(6) {
        border-color: $sixDotBg;
        background-color: $sixDotOpacity;
      }

      &:nth-child(7) {
        border-color: $sevenDotBg;
        background-color: $sevenDotOpacity;
      }

      &:nth-child(8) {
        border-color: $eightDotBg;
        background-color: $eightDotOpacity;
      }

      &:nth-child(9) {
        border-color: $nineDotBg;
        background-color: $nineDotOpacity;
      }

      &:nth-child(10) {
        margin-right: 0;
        border-color: $tenDotBg;
        background-color: $tenDotOpacity;
      }

      i {
        display: inline-block;
        font-size: 12px;
        margin-left: 9px;
        cursor: pointer;
        transition: $transition;

        &:hover {
          color: $red-color;
        }
      }
    }
  }

  .clear-btn {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    height: 32px;
    padding: 4px 0;
    margin-left: 16px;
    color: $violet;
    font-family: $openSansSemiBold;
    font-size: 14px;
    font-style: normal;
    line-height: 24px;
    cursor: pointer;
    transition: $transition;
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }

    &:hover {
      color: $violet-hover;
    }

    img {
      width: 18px;
      height: 18px;
      display: inline-block;
      margin: 0 8px 0 0;
    }
  }
}

// Add/Edit events
app-event-form,
app-admission-event-form,
app-add-new-event {
  input {
    font-family: $openSansSemiBold;
    @include placeholder {
      font-size: 12px;
      color: #9D9EA8;
      font-family: $openSansSemiBold;
    }
  }

  label {
    input {
      &:focus {
        border-color: $violet-hover;
      }
    }

    &.error {
      input {
        border-color: $red-color;
      }
    }
  }

  .by-date {
    .icon-calendar {
      right: 15px;
      top: 10px;
      font-size: 15px;
      color: #959FB8;
    }

    &.left {
      &.showCalendar {
        .flatpickr-calendar {
          &.inline {
            right: 0 !important;
          }
        }
      }
    }

    &.showCalendar {
      .flatpickr-calendar {
        &.inline {
          top: -75px !important;
          @media screen and (max-height: 500px) {
            top: inherit !important;
          }
        }
      }
    }
  }

  .time {
    label {
      &.error {
        app-time-select {
          .time-picker-select {
            .input-container {
              border-color: $red-color !important;
            }
          }
        }
      }
    }
  }

  .flatpickr-calendar {
    &.animate {
      &.inline {
        &.showTimeInput {
          left: 0;
        }
      }
    }
  }

  .time-picker-select {
    .input-container {
      padding: 0 !important;

      > div {
        width: 100%;
      }

      .input-value {
        width: 100% !important;
        @media screen and (max-width: 375px) {
          width: 100% !important;
        }
      }

      .arrow-time {
        display: none !important;
      }
    }
  }

  .has-error {
    &.ng-touched {
      border-color: $red-color;
    }
  }

  .error-msg {
    display: block !important;
  }

  .flatpickr-current-month {
    color: $black-color;
  }

  .numInput {
    &.cur-year {
      color: $black-color !important;
      padding-right: 0 !important;
    }
  }

  .add-event {
    position: fixed;
    z-index: 99999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 30px);
    max-width: 680px;
    background-color: $white-color;
    box-shadow: 0 4px 17px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    padding: 0;
    overflow-y: auto;
    @media screen and (max-height: 500px) {
      max-height: 70vh;
    }

    &__close {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .icon-close {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        color: #4B5263;
        font-size: 14px;
      }
    }

    &__title {
      margin: 0;
      padding: 0 0 30px 0;
      color: #494A58;
      text-align: center;
      font-family: $openSansSemiBold;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.32px;
      text-transform: uppercase;
    }

    &__container {
      max-height: calc(100vh - 20px);
      padding: 0 40px 40px 40px;
      @media screen and (max-width: 767px) {
        max-height: calc(100vh - 150px);
        padding: 0 20px 10px 20px;
      }
    }

    &__btn-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 8px;

      .save {
        min-width: 153px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        padding: 0 15px;
        background-color: $violet;
        border-radius: 3px;
        font-size: 14px;
        line-height: 19px;
        font-family: $openSansSemiBold;
        color: $white-color;
        transition: $transition;
        margin-bottom: 20px;

        &:disabled {
          opacity: .5;
          cursor: default;

          &:hover {
            opacity: .5;
            background-color: $violet;
          }
        }

        &:hover {
          background-color: $violet-hover;
        }
      }
    }

    &__row {
      display: flex;
      align-items: flex-start;
      width: 100%;
      padding: 0 0 16px 0;
      @media screen and (max-width: 600px) {
        flex-wrap: wrap;
      }
    }

    &__cell {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      width: 144px;
      min-height: 36px;
      color: #818AA1;
      font-family: $openSansSemiBold;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      @media screen and (max-width: 600px) {
        width: 100%;
        padding-bottom: 10px;
      }

      i {
        font-size: 18px;
        display: inline-block;
        margin-right: 6px;
        color: #959FB8;
      }

      img {
        width: 22px;
        height: 22px;
        display: inline-block;
        margin-right: 3px;
        position: relative;
        left: -2px;
      }
    }

    &__value {
      width: 100%;

      > input {
        width: 100%;
      }

      label {
        display: block;
        width: 100%;

        input {
          width: 100%;
        }
      }

      .by-date {
        padding: 0;
        margin: 0;

        input {
          height: 36px;
        }

        .icon-calendar {
          top: 12px;
        }
      }

      .calendar {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .date {
          width: calc(50% - 10px);

          &:only-child {
            width: 100%;

            input {
              width: 100%;
            }
          }
        }
      }
    }

    &__time {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      > label {
        width: calc(50% - 10px);

        &.start {
          position: relative;

          &::before {
            content: '-';
            display: block;
            position: absolute;
            right: -12px;
            top: 8px;
            color: #494A58;
            font-family: $openSansSemiBold;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            line-height: 18px;
          }
        }
      }
    }

    &__error {
      display: flex;
      justify-content: flex-end;
      padding: 0 0 28px 0;

      span {
        width: calc(100% - 144px);
        display: block;
        padding: 4px;
        border-radius: 3px;
        border: 1px solid $red-color;
        background: #FFEFEF;
        color: #DC7070;
        font-family: $openSansSemiBold;
        font-size: 11px;
        font-weight: normal;
        line-height: 18px;
        text-align: center;
        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }

  .no-arrow {
    .ng-arrow-wrapper {
      display: none;
    }
  }

  .ng-select {
    .ng-select-container {
      .ng-value-container {
        position: initial !important;
        height: initial !important;
        width: initial !important;

        .ng-input {
          > input {
            cursor: auto !important;
          }
        }

        .ng-value {
          position: initial !important;
        }
      }
    }

    &.ng-select-multiple {
      .ng-select-container {
        overflow: initial !important;
      }
    }
  }
}

// Info event
app-event-info,
app-admission-about-event,
app-about-event {
  .info-event {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 110;
    transform: translate(-50%, -50%);
    width: calc(100% - 30px);
    max-width: 680px;
    background-color: $white-color;
    padding: 0 0 16px 0;
    box-shadow: 0 4px 17px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    max-height: 60vh;
    overflow-y: auto;

    .space {
      display: inline-block;
      padding: 0 5px;
    }

    &__controls {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 12px 12px 4px 12px;

      &-item {
        width: 48px;
        height: 48px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: $transition;

        &:hover {
          opacity: $hoverOpacity;
        }

        img {
          width: 20px;
          height: 20px;
        }

        .icon-close {
          position: relative;
          top: 1px;
        }
      }
    }

    &__container {
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 8px;
      @media screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &__head {
      padding: 0 0 20px 0;
    }

    &__title {
      color: #2B303D;
      font-family: $openSansBold;
      font-size: 20px;
      font-style: normal;
      line-height: 140%;
      padding: 0 0 4px 22px;
      position: relative;

      i {
        display: block;
        position: absolute;
        left: 0;
        top: 9px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
      }
    }

    &__row {
      display: flex;
      align-items: flex-start;
      padding: 0 0 16px 0;
      @media screen and (max-width: 600px) {
        flex-wrap: wrap;
      }
    }

    &__cell {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      width: 108px;
      color: #818AA1;
      font-family: $openSansSemiBold;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      @media screen and (max-width: 600px) {
        width: 100%;
        padding-bottom: 10px;
      }

      i {
        font-size: 18px;
        display: inline-block;
        margin-right: 6px;
        color: #959FB8;
      }

      img {
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 4px;
        position: relative;
        left: -1px;
      }
    }

    &__value {
      width: 100%;
      color: #2B303D;
      font-family: $openSansRegular;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;

      &.flex {
        display: flex;
        align-items: center;
      }

      a {
        color: $violet;
        font-family: $openSansSemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 20px;
        text-decoration: underline;
        transition: $transition;

        &:hover {
          color: $violet-hover;
          text-decoration: none;
        }
      }
    }

    &__error {
      display: flex;
      align-items: center;
      color: #CC3954;
      font-family: $openSansRegular;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 20px;

      img {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
    }
  }
}
