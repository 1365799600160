@import "../../assets/styles/utils/vars";

@mixin can-toggle-branding(
  $can-toggle-off-color: #4c5560,
  $can-toggle-on-color: $violet,
  $can-toggle-inactive-text: #4c5560,
  $can-toggle-transition: cubic-bezier(0,1,0.5,1)
) {
  input[type="checkbox"] {
    &[disabled] ~ label {
      color: $can-toggle-off-color;
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          background-color: #f2f2f8;

          &:after {
            color: $can-toggle-on-color;
          }
        }
      }
    }
  }

  label {
    .can-toggle__label-text {
      flex: 1;
    }

    .can-toggle__switch {
      &:before {
        color: $can-toggle-inactive-text;
        text-transform: capitalize;
        font-family: $openSansRegular;
        font-weight: normal;
        background-color: #f8f8fb;
      }

      &:after {
        color: $can-toggle-off-color;
        text-transform: capitalize;
        font-family: $openSansRegular;
        font-weight: normal;
      }
    }
  }
}

@mixin can-toggle-appearance(
  $can-toggle-width: 120px,
  $can-toggle-height: 30px,
  $can-toggle-border-radius: 3px,
  $can-toggle-offset: 0,
  $can-toggle-label-font-size: 12px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
) {
  $can-toggle-switch-width: calc($can-toggle-width / 2);
  input[type="checkbox"] {
    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after {
            transform: translate3d($can-toggle-width - ($can-toggle-switch-width + $can-toggle-offset), 0, 0);
            color: $violet !important;
            @media screen and (max-width: 500px) {
              transform: translate3d(0px, 0, 0);
            }
          }
        }
      }
    }
  }

  label {
    font-size: $can-toggle-label-font-size;

    .can-toggle__switch {
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;
      @media screen and (max-width: 500px) {
        flex: 0 0 100%;
      }

      &:before {
        left: calc($can-toggle-width / 2);
        font-size: $can-toggle-switch-font-size;
        line-height: $can-toggle-height;
        width: calc($can-toggle-width / 2);
        padding: 0 12px;
        @media screen and (max-width: 500px) {
          width: 50%;
          left: 50%;
        }
      }

      &:after {
        top: $can-toggle-offset;
        left: $can-toggle-offset;
        border-radius: calc($can-toggle-border-radius / 2);
        width: $can-toggle-switch-width - $can-toggle-offset;
        line-height: 30px;
        font-size: $can-toggle-switch-font-size;
        @media screen and (max-width: 500px) {
          width: 50%;
        }
      }
    }
  }
}


.can-toggle {
  position: relative;
  width: 122px;
  margin-top: 13px;
  margin-bottom: 50px;
  @media screen and (max-width: 500px) {
    width: 100%;
  }

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &[disabled] ~ label {
      pointer-events: none;

      .can-toggle__switch {
        opacity: 0.4;
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:before {
            content: attr(data-checked);
            left: 0;
          }

          &:after {
            content: attr(data-unchecked);
            @media screen and (max-width: 500px) {
              left: 50%;
              width: 50%;
            }
          }
        }
      }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: inline-flex !important;
    align-items: center;
    padding: 0 !important;
    margin: 0 !important;
    border: 1px solid transparent;
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;

    .can-toggle__label-text {
      flex: 1;
      padding-left: 32px;
    }

    .can-toggle__switch {
      position: relative;
      z-index: 0;
      padding-bottom: 0;

      &:before {
        content: attr(data-unchecked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
        display: block;
        border: 1px solid #c8c8de;
        height: 30px;
      }

      &:after {
        content: attr(data-checked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: #f2f2f8;
        border: 1px solid $violet;
        color: $violet !important;
        display: block;
        top: 0;
        height: 30px;
      }
    }
  }

  @include can-toggle-branding;
  @include can-toggle-appearance;
}
