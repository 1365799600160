@import "../../assets/styles/utils/vars";

app-admission-application,
app-discharge-summary,
app-red-flag-form,
app-new-employee-application {
  .ng-value {
    font-family: $openSansRegular;
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 7px;
  }

  .ng-input {
    padding-left: 0 !important;
  }

  .ng-option {
    font-family: $openSansRegular;
    font-size: 12px;
  }

  .ng-arrow-wrapper {
    display: none;
  }

  .ng-clear-wrapper {
    display: none;
  }

  .ng-value-container {
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

app-discharge-summary,
app-red-flag-form,
app-admission-application {
  .ng-value {
    top: 9px;
  }
}

.ng-select-chips {
  .ng-dropdown-panel {
    position: absolute !important;
    top: calc(100% + 4px);
    border-radius: 3px;
    background-color: $white-color;
    box-shadow: 0 3px 11px 1px rgba(46, 55, 65, 0.10);
    border: 0;

    .ng-dropdown-panel-items {
      max-height: 324px;
      @media screen and (max-width: 767px) {
        max-height: 145px;
      }
      @media screen and (max-height: 650px) {
        max-height: 200px;
      }
      @media screen and (max-height: 500px) {
        max-height: 145px;
      }

      .ng-option {
        background-color: $white-color;
        color: #4C5560;
        font-family: $openSansRegular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        display: flex;
        align-items: center;
        padding: 0 16px;

        .form-group {
          width: 100%;
          position: relative;

          input {
            position: absolute;
            z-index: 5;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;

            &:checked {
              + label {
                &:after {
                  content: '';
                  display: block;
                  position: absolute;
                  top: 12px;
                  left: 6px;
                  width: 4px;
                  height: 7px;
                  border: solid $violet;
                  border-width: 0 2px 2px 0;
                  transition: initial;
                  transform: rotate(41deg);
                }

                &:before {
                  border-color: $violet;
                  background-color: #e7e8f8;
                }
              }
            }

            &:hover {
              + label {
                &:before {
                  border-color: #ABACBB;
                }
              }
            }
          }

          label {
            position: relative;
            display: flex;
            width: 100%;
            align-items: center;
            cursor: pointer;
            padding: 8px 0 8px 26px;
            margin: 0;

            &::before {
              content: '';
              -webkit-appearance: none;
              position: absolute;
              top: 8px;
              left: 0;
              display: inline-block;
              cursor: pointer;
              width: 16px;
              height: 16px;
              min-width: 16px;
              min-height: 16px;
              border-radius: 3px;
              border: 1px solid #c8c8de;
            }
          }
        }

        &.ng-option-disabled {
          color: #cccccc;
          font-size: 12px;
          padding: 10px;
        }

        &.ng-option-selected,
        &.ng-option-selected.ng-option-marked {
          background-color: $white-color;
        }
      }
    }
  }

  .ng-arrow-wrapper {
    position: relative;
    top: -1px;
  }

  .ng-clear-wrapper {
    .ng-clear-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ng-clear {
      position: relative;
      top: 1px;
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      min-height: 36px;
      border-radius: 3px;
      border: 1px solid #CFD0DE !important;
      background-color: #FFF;

      .ng-value-container {
        padding: 0 0 0 4px;

        .ng-input {
          padding: 0;
        }

        .ng-value {
          color: #4C5560;
          font-size: 12px;
          border-radius: 3px;
          margin: 2px 4px 2px 0;
          padding: 5px 4px 5px 2px;
          background-color: #EBECFC;

          .ng-value-icon {
            transition: $transition;

            &:hover {
              background-color: transparent;
              color: $red-color;
            }
          }
        }
      }
    }
  }
}

.ng-select-chips-new {
  .ng-dropdown-panel {
    position: absolute !important;
    top: calc(100% + 4px);
    border-radius: 3px;
    background-color: $white-color;
    box-shadow: 0 3px 11px 1px rgba(46, 55, 65, 0.10);
    border: 0;

    .ng-dropdown-panel-items {
      max-height: 324px;
      @media screen and (max-width: 767px) {
        max-height: 145px;
      }
      @media screen and (max-height: 650px) {
        max-height: 200px;
      }
      @media screen and (max-height: 500px) {
        max-height: 145px;
      }

      .ng-option {
        background-color: $white-color;
        color: #4C5560;
        font-family: $openSansRegular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        display: flex;
        align-items: center;
        padding: 0 16px;

        .form-group {
          width: 100%;
          position: relative;

          input {
            position: absolute;
            z-index: 5;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;

            &:checked {
              + label {
                &:after {
                  content: '';
                  display: block;
                  position: absolute;
                  top: 12px;
                  left: 6px;
                  width: 4px;
                  height: 7px;
                  border: solid $violet;
                  border-width: 0 2px 2px 0;
                  transition: initial;
                  transform: rotate(41deg);
                }

                &:before {
                  border-color: $violet;
                  background-color: #e7e8f8;
                }
              }
            }

            &:hover {
              + label {
                &:before {
                  border-color: #ABACBB;
                }
              }
            }
          }

          label {
            position: relative;
            display: flex;
            width: 100%;
            align-items: center;
            cursor: pointer;
            padding: 8px 0 8px 26px;
            margin: 0;

            &::before {
              content: '';
              -webkit-appearance: none;
              position: absolute;
              top: 8px;
              left: 0;
              display: inline-block;
              cursor: pointer;
              width: 16px;
              height: 16px;
              min-width: 16px;
              min-height: 16px;
              border-radius: 3px;
              border: 1px solid #c8c8de;
            }
          }

          &.disabled {
            input {
              &:checked {
                + label {
                  &:after {
                    border: solid #cccccc;
                    border-width: 0 2px 2px 0;
                    transition: initial;
                    transform: rotate(41deg);
                  }

                  &:before {
                    border-color: #cccccc;
                    background-color: #e7e8f8;
                  }
                }
              }
            }
          }
        }

        &.ng-option-disabled {
          padding: 16px;
          pointer-events: none;
          border-radius: 3px;
          background-color: $white-color;
          color: #4C5560;
          font-family: $openSansRegular;
          font-size: 12px;
          font-style: normal;
          font-weight: normal;
          line-height: 16px;
        }

        &.ng-option-selected,
        &.ng-option-selected.ng-option-marked {
          background-color: $white-color;
        }
      }
    }

    .ng-dropdown-header {
      padding: 0;
      border: none;

      .form-group {
        width: 100%;
        position: relative;

        background-color: #ffffff;
        color: #4C5560;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        display: flex;
        align-items: center;
        padding: 0 16px;

        input {
          position: absolute;
          z-index: 5;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;

          &:checked {
            + label {
              &:after {
                content: '';
                display: block;
                position: absolute;
                top: 12px;
                left: 6px;
                width: 4px;
                height: 7px;
                border: solid $violet;
                border-width: 0 2px 2px 0;
                transition: initial;
                transform: rotate(41deg);
              }

              &:before {
                border-color: $violet;
                background-color: #e7e8f8;
              }
            }
          }

          &:hover {
            + label {
              &:before {
                border-color: #ABACBB;
              }
            }
          }
        }

        label {
          position: relative;
          display: flex;
          width: 100%;
          align-items: center;
          cursor: pointer;
          padding: 8px 0 8px 26px;
          margin: 0;
          font-weight: bold;

          &::before {
            content: '';
            -webkit-appearance: none;
            position: absolute;
            top: 8px;
            left: 0;
            display: inline-block;
            cursor: pointer;
            width: 16px;
            height: 16px;
            min-width: 16px;
            min-height: 16px;
            border-radius: 3px;
            border: 1px solid #c8c8de;
          }
        }
      }
    }
  }

  .ng-arrow-wrapper {
    position: relative;
    top: -1px;
  }

  .ng-clear-wrapper {
    display: none;
    align-items: center;
    justify-content: center;
  }

  &.ng-select-multiple {
    .ng-select-container {
      min-height: 36px;
      border-radius: 3px;
      border: 1px solid #CFD0DE !important;
      background-color: #FFF;

      .ng-value-container {
        padding: 0 0 0 7px;

        .ng-input {
          padding: 0;
        }

        .ng-value {
          display: flex;
          flex-direction: row-reverse;
          color: #4C5560;
          font-size: 12px;
          border-radius: 3px;
          margin: 2px 4px 2px 0;
          padding: 5px 4px 5px 2px;
          background-color: #EBECFC;

          .ng-value-icon {
            transition: $transition;


            &:hover {
              background-color: transparent;
              color: $red-color;
            }
          }

          .left {
            border: none !important;
            font-size: 13px;
            font-weight: bold;
            color: $red-color;
          }

          &.disabled {
            pointer-events: none;

            .left {
              border: none !important;
              font-size: 13px;
              font-weight: bold;
              color: #ABACBB;
            }
          }
        }
      }
    }
  }
}

