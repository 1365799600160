@import "../utils/vars";
@import "../utils/mixins";

app-update-profile {
  .ng-select {
    width: 100%;

    .ng-select-container {
      .ng-value-container {
        padding-left: 5px;

        .ng-input {
          > input {
            outline: 0;
            box-shadow: none;
            padding-left: 5px;
            font-family: $openSansSemiBold;
          }
        }
      }

      &:hover {
        background-color: transparent;
      }
    }

    .ng-option-label,
    .ng-value-label {
      color: #4c5560 !important;
    }

    &.ng-select-single {
      .ng-select-container {
        .ng-value-container,
        .ng-value-container .ng-value {
          overflow: hidden;
          width: 95%;
        }

        .ng-value-container {
          .ng-input {
            padding-left: 0;
            padding-right: 0;
            top: 0;
          }
        }
      }
    }
  }

  .err-msg {
    padding: 15px 0;
    font-size: 12px;
  }

  .tooltip-wr {
    position: relative;

    .tooltip {
      position: absolute;
      bottom: calc(100% + 10px);
      left: 25px;
      background-color: #8e8ea1;
      min-height: 26px;
      border-radius: 3px;
      font-family: $openSansSemiBold;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.17;
      letter-spacing: 0.1px;
      color: $white-color;
      padding: 7px;
      width: 230px;
      z-index: -10;
      visibility: hidden;
      opacity: 0;
      transition: opacity ease 0.15s;

      &:before {
        content: '';
        position: absolute;
        left: 10px;
        bottom: -4px;
        display: block;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #8e8ea1;
      }
    }

    .icon-lock {
      font-size: 10px;
      position: relative;
      top: -3px;
    }

    &:hover {
      .tooltip {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .err-message {
    padding-left: 85px;
  }

  .update-profile {
    height: 100vh;
    position: fixed;
    z-index: 105;
    right: 0;
    top: 0;
    bottom: 0;
    width: 402px;
    background-color: $white-color;
    box-shadow: $drop-box-shadow;
    padding: 0 0 150px 0;
    overflow-y: auto;
    @include scrollbar($scrollBarColor, transparent);
    @media screen and (max-width: 767px) {
      width: 320px;
    }

    &__container {
      width: 100%;
      padding: 0 30px;
      @media screen and (max-width: 767px) {
        padding: 0 15px;
      }
    }

    &__head {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 22px 0 15px 0;

      i {
        font-size: 12px;
        color: #9d9ea8;
        cursor: pointer;
        transition: $transition;

        &:hover {
          color: #4c5560;
        }
      }
    }

    &__flex {
      display: flex;
      align-items: center;
      padding-bottom: 35px;
      width: 100%;
      border-bottom: 1px solid #e8e9f4;
    }

    &__overlay {
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 0;
      width: 0;
      border-radius: 50%;
      background-color: rgba(101, 101, 128, 0.82);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $openSansSemiBold;
      font-size: 13px;
      font-weight: normal;
      line-height: 1.08;
      letter-spacing: 0.1px;
      color: $white-color;
      transition: opacity ease 0.15s;
    }

    &__img {
      width: 76px;
      height: 76px;
      flex-shrink: 0;
      position: relative;
      cursor: pointer;
      margin-right: 16px;
      border-radius: 50%;

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        bottom: -5px;
        right: 7px;
        background: url("../../../assets/img/icons/edit.svg") no-repeat center;
        background-size: contain;
      }

      &:hover {
        .update-profile {
          &__overlay {
            opacity: 1;
            height: auto;
            width: auto;
          }
        }
      }

      img {
        border-radius: 50%;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
      }

      &.no-edit {
        &:after {
          display: none;
        }
      }
    }

    &__info {
      width: 100%;

      .name {
        display: block;
        padding-bottom: 7px;
        font-family: $openSansSemiBold;
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0.3px;
        color: #494a58;
        text-transform: uppercase;
        word-break: break-all;
      }

      .position {
        display: block;
        font-family: $openSansSemiBold;
        font-size: 12px;
        font-weight: normal;
        color: #9d9ea8;
      }
    }
  }

  .update-info {
    padding-top: 55px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8e9f4;
    width: 100%;

    .confirm-edit {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding: 2px 0 0 20px;

      &__yes,
      &__no {
        flex-shrink: 0;
        cursor: pointer;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $transition;

        svg {
          width: 6px;
          height: auto;
        }
      }

      &__yes {
        border: 1px solid $green-color;
        margin-right: 7px;

        svg {
          path {
            fill: $green-color;
          }
        }

        &:hover {
          background-color: $green-color;

          svg {
            path {
              fill: $white-color;
            }
          }
        }
      }

      &__no {
        border: 1px solid $red-color;

        svg {
          path {
            &:first-child {
              fill: $white-color;
            }

            &:nth-child(2) {
              fill: $red-color;
            }
          }
        }

        &:hover {
          background-color: $red-color;

          svg {
            path {
              &:nth-child(2) {
                fill: $white-color;
              }
            }
          }
        }
      }
    }

    &__row {
      position: relative;
      width: 100%;
      padding-bottom: 20px;
    }

    &__flex {
      display: flex;
      align-items: center;

      span {
        display: block;
        width: 85px;
        flex-shrink: 0;
        font-family: $openSansSemiBold;
        font-size: 13px;
        font-weight: normal;
        line-height: 1.08;
        letter-spacing: 0.1px;
        color: #9d9ea8;

        &.value {
          color: #4C5560;
          padding-left: 5px;
          padding-top: 5px;
          padding-bottom: 5px;
          display: block;
          width: auto;
        }
      }

      label {
        display: flex;
        align-items: center;
        width: 100%;

        input {
          height: auto;
          width: 100%;
          padding: 5px 30px 5px 5px;
          margin: 0;
          border: 0;
          border-bottom: 1px solid $violet;
          border-radius: 0;
          font-family: $openSansSemiBold;

          &:active,
          &:focus {
            box-shadow: none;
          }

          &.not-edit {
            padding-right: 5px;
          }
        }

        &.error {
          input {
            border-color: $red-color;
          }
        }
      }

      .icon-edit-header {
        display: inline-block;
        position: absolute;
        z-index: 5;
        right: 5px;
        top: 7px;
        font-size: 14px;
        color: #8e8ea1;
        cursor: pointer;
        transition: $transition;

        &:hover {
          color: $violet;
        }
      }

      &.not-editable {
        label {
          input {
            border-color: transparent;
          }
        }

        i {
          display: none;
        }
      }
    }

    &__sign {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        display: block;
        width: 85px;
        flex-shrink: 0;
        font-family: $openSansSemiBold;
        font-size: 13px;
        font-weight: normal;
        line-height: 1.08;
        letter-spacing: 0.1px;
        color: #9d9ea8;
      }

      img {
        max-height: 50px;
        width: auto;
        max-width: 150px;
        margin-right: 20px;
      }

      .sign-edit {
        color: #9d9ea8;
        transition: $transition;

        &:hover {
          color: $violet;
        }
      }
    }
  }

  .update-password {
    padding: 50px 0 0 0;
    width: 100%;

    label {
      display: block;
      width: 100%;
      padding-bottom: 25px;

      &:last-of-type {
        padding-bottom: 55px;
      }

      span {
        font-family: $openSansSemiBold;
        font-size: 12px;
        font-weight: normal;
        color: #9d9ea8;
        display: block;
        width: 100%;
        padding-bottom: 5px;
      }

      input {
        display: block;
        width: 100%;

        &[readonly] {
          background-color: #f1f1f1;
          border-color: #dddee8;
          pointer-events: none;

          &:active,
          &:focus {
            box-shadow: none;
          }
        }
      }

      &.error {
        span {
          color: $red-color;
        }

        input {
          border-color: $red-color
        }
      }
    }

    .err-message {
      padding-left: 0;
    }

    &__title {
      text-transform: uppercase;
      font-family: $openSansRegular;
      font-size: 12px;
      line-height: 1.42;
      letter-spacing: 0.2px;
      color: #4c5560;
      padding-bottom: 31px;
    }

    &__btn {
      border-top: 1px solid #e8e9f4;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-top: 55px;
      padding-bottom: 50px;
    }

    &__success {
      display: flex;
      align-items: center;
      padding-top: 15px;
      font-size: 12px;
      color: $green-color;

      i {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  .update-avatar {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    &__head {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 35px 0;

      i {
        font-size: 12px;
        color: #9d9ea8;
        cursor: pointer;
        transition: $transition;

        &:hover {
          color: #4c5560;
        }
      }

      .back-btn {
        border: 0;
        background: 0 0;
        font-family: $openSansSemiBold;
        font-size: 13px;
        font-weight: normal;
        line-height: 1.08;
        letter-spacing: 0.1px;
        color: $violet;
        transition: $transition;

        i {
          color: $violet;
          font-size: 9px;
          display: inline-block;
          transform: scaleX(-1);
        }

        &:hover {
          color: $violet-hover;
        }
      }
    }
  }

  .upload-pic {
    width: 100%;

    &__head {
      display: flex;
      width: 100%;

      .new-picture {
        font-family: $openSansSemiBold;
        font-size: 13px;
        font-weight: normal;
        line-height: 1.31;
        letter-spacing: 0.1px;
        color: #4c5560;
        background-color: #f8f8fb;
        padding: 11px 14px;
        display: inline-block;
        border: 1px solid #e8e9f4;
        border-bottom: 0;
        border-radius: 6px 6px 0 0;
      }

      .delete-photo {
        font-family: $openSansSemiBold;
        font-size: 13px;
        font-weight: normal;
        line-height: 1.31;
        letter-spacing: 0.1px;
        color: $red-color;
        border: 0;
        background: 0 0;
        padding: 0;
        margin-left: 20px;
        transition: $transition;
        @media screen and (max-width: 1200px) {
          margin-left: 32px;
        }

        &:hover {
          color: $red-color-hover;
        }
      }
    }

    &__content {
      border-top: 1px solid #e8e9f4;
      min-height: calc(100vh - 100px);
      background-color: #f8f8fb;
      padding: 20px 30px 0 30px;
      margin-top: -1px;
    }
  }

  .custom-file-uploader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    label {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .document-info {
      display: block;
      width: 100%;
      padding-top: 16px;
      font-family: $openSansSemiBold;
      font-size: 12px;
      font-weight: normal;
      color: #b5b6c0;
      @media screen and (max-width: 767px) {
        padding-top: 24px;
      }
    }

    .upload-btn {
      height: 27px;
      border-radius: 3px;
      padding: 0 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $openSansSemiBold;
      font-size: 12px;
      font-weight: normal;
      color: $violet;
      border: 1px solid $violet;
      transition: $transition;
      margin-right: 14px;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    .fileName {
      display: inline-flex;
      align-items: center;
      font-family: $openSansRegular;
      font-size: 12px;
      color: #b5b6c0;

      .icon {
        flex-shrink: 0;
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-left: 10px;
        background: url("../../../assets/img/icons/clear.svg") no-repeat center;
        transition: $transition;
        cursor: pointer;
      }

      &.file-upload {
        font-family: $openSansRegular;
        font-size: 12px;
        font-weight: normal;
        color: #4c5560;
        word-break: break-all;
        @media screen and (max-width: 767px) {
          margin-top: 14px;
        }

        &:hover {
          .icon {
            background: url("../../../assets/img/icons/clear-hover.svg") no-repeat center;
          }
        }
      }
    }

    .upload {
      width: 100%;
      display: block;
      padding-top: 24px;
      @media screen and (max-width: 767px) {
        padding-top: 38px;
      }

      button {
        border: 0;
        height: 36px;
        padding: 0 38px;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }

    &:hover {
      .upload-btn {
        color: $violet-hover;
        border-color: $violet-hover;
      }
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  .loading {
    position: fixed;
    z-index: 20;
    right: 0;
    top: 0;
    bottom: 0;
    width: 402px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.72);
    backdrop-filter: blur(4px);
    @media screen and (max-width: 767px) {
      width: 320px;
    }

    .icon {
      width: 26px;
      height: 26px;
      flex-shrink: 0;
      margin-bottom: 15px;
      animation: bouncingLoader 0.6s infinite alternate;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
