@import "../../../../assets/styles/utils/vars";
@import "../../../../assets/styles/utils/mixins";

.color-picker {
  border-color: transparent !important;
  border-radius: 3px;
  box-shadow: 0 2px 12px 0 rgba(46, 55, 65, 0.16);
  background-color: $white-color;
  padding: 24px 0 30px 0;
  @media screen and (max-width: 600px) {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  .arrow.arrow-right,
  .left,
  .alpha,
  .type-policy {
    display: none !important;
  }

  .box,
  .right {
    padding: 0 !important;
  }

  .hue-alpha {
    padding: 20px 35px 0 35px !important;
  }

  .hue {
    height: 12px !important;
    margin-bottom: 30px !important;
    border-radius: 15px !important;
  }

  .cursor {
    width: 12px !important;
    height: 12px !important;
    border: 2px solid $white-color !important;
  }

  .hex-text {
    padding: 0 35px !important;

    .box {
      + .box {
        display: none;
      }

      input {
        display: block;
        margin: 0 auto;
        width: 75px;
        height: 28px;
        max-width: 75px;
        border-radius: 3px;
        box-shadow: 0 1px 4px 0 rgba(100, 105, 183, 0.24);
        background-color: $white-color;
        font-family: $openSansSemiBold;
        font-size: 13px;
        font-weight: normal;
        color: #4c5560;

        &::selection {
          background-color: #a9c9f9;
        }
      }
    }
  }
}
