@import "../utils/vars";

$loadingMinHeight: 375px;

app-add-lesson,
app-upload-documents,
app-login-by-microsoft-redirect-url,
app-choosed-documents-admission,
app-choosed-documents,
app-root {
  .overlay {
    position: absolute;
    z-index: 20;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.72);
    backdrop-filter: blur(4px);

    img {
      width: 100%;
      max-width: 120px;
      height: auto;
    }
  }
}

.empty-table {
  width: 100%;
  min-height: $loadingMinHeight;
  padding: 64px 10px 80px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 4px 4px;
  background-color: $white-color;
  box-shadow: 0 2px 12px 0 rgba(46, 55, 65, 0.07);

  &__img {
    display: inline-block;
    width: 100%;
    max-width: 164px;
    margin-bottom: 12px;
    @media screen and (max-width: 600px) {
      max-width: 134px;
    }
  }

  &__text {
    color: #2B303D;
    text-align: center;
    font-family: $openSansRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
}

.loading-table {
  width: 100%;
  min-height: ($loadingMinHeight + 34px);
  padding: 64px 10px 80px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 4px 4px;
  background-color: $white-color;
  box-shadow: 0 2px 12px 0 rgba(46, 55, 65, 0.07);

  img {
    width: 100%;
    max-width: 120px;
    height: auto;
  }
}
